import axios from 'axios';
import '../../config/config';
import React, { useState } from 'react';
import Navbar from './Navbar';
import { Link, useNavigate } from 'react-router-dom';

const AddAdminStaff = () => {

    const [inputField, setInputField] = useState({
        "AdStaffName": "",
        "PhNo": "",
        "Address": "",
        "AadharNo": "",
        "Email": "",
        "Password": "",
        "confirmpassword": ""
    });

    const [errors, setErrors] = useState({});

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate()

    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false); // New state for overlay

    const apiUrl = global.config.urls.api.server + '/api/lms/addAdminStaff';

    const inputHandler = (event) => {
        setErrors({})
        setInputField({ ...inputField, [event.target.name]: event.target.value });

    };

    const closeWaitingModal = () => {
        setShowOverlay(false)
        setShowWaitingModal(false)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(inputField);
        if (Object.keys(validationErrors).length === 0) {
            let axiosConfig = {
                headers: {
                    "content-type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "token": sessionStorage.getItem("admtoken"),
                    "key": sessionStorage.getItem("admkey")
                }
            }
            let data = {
                "AdStaffName": inputField.AdStaffName,
                "PhNo": inputField.PhNo,
                "Address": inputField.Address,
                "AadharNo": inputField.AadharNo,
                "Email": inputField.Email,
                "Password": inputField.Password,
                "confirmpassword": inputField.confirmpassword
            };
            setShowWaitingModal(true)
            setShowOverlay(true)
            axios.post(apiUrl, data, axiosConfig).then(
                (response) => {
                    if (response.data.status === 'success') {
                        closeWaitingModal()
                        setTimeout(() => {
                            alert("AdminStaff Added Successfully.");
                            setInputField({
                                AdStaffName: "",
                                PhNo: "",
                                Address: "",
                                AadharNo: "",
                                Email: "",
                                Password: "",
                                confirmpassword: ""
                            });
                        }, 500)

                    } else {
                        closeWaitingModal()
                        if (response.data.status === 'Validation failed' && response.data.data.name) {
                            setTimeout(()=>{
                                alert(response.data.data.name);
                            }, 500)
                        } else if (response.data.status === 'Validation failed' && response.data.data.mobile) {
                            setTimeout(()=>{
                                alert(response.data.data.mobile);
                            }, 500)
                        } else if (response.data.status === 'Validation failed' && response.data.data.address) {
                            setTimeout(()=>{
                                alert(response.data.data.address);
                            }, 500)
                        } else if (response.data.status === 'Validation failed' && response.data.data.aadharno) {
                            setTimeout(()=>{
                                alert(response.data.data.aadharno);
                            }, 500)
                        } else if (response.data.status === 'Validation failed' && response.data.data.email) {
                            setTimeout(()=>{
                                alert(response.data.data.email);
                            }, 500)
                        } else if (response.data.status === 'Validation failed' && response.data.data.password) {
                            setTimeout(()=>{
                                alert(response.data.data.password);
                            }, 500)
                        } else if (response.data.status === "Unauthorized User !!!") {
                            navigate("/")
                            sessionStorage.clear()
                        } else {
                            closeWaitingModal()
                            setTimeout(() => {
                                alert(response.data.status)
                            }, 500)
                        }
                    }
                });
        } else {
            setErrors(validationErrors);
        }
    };


    const validateForm = (data) => {
        let errors = {};

        if (!data.AdStaffName.trim()) {
            errors.AdStaffName = 'Admin Staff Name is required';
        }
        if (!data.PhNo.trim()) {
            errors.PhNo = 'Mobile number is required';
        } else if (!/^\+91[6-9]\d{9}$|^\+91\s?[6-9]\d{9}$|^[6-9]\d{9}$/.test(data.PhNo)) {
            errors.PhNo = 'Invalid Mobile Number'
        }

        if (!data.Address.trim()) {
            errors.Address = 'Address is required';
        }
        if (!data.AadharNo.trim()) {
            errors.AadharNo = 'Aadhar Number is required';
        }
        if (!data.Email.trim()) {
            errors.Email = 'Email is required';
        }
        if (!data.Password.trim()) {
            errors.Password = 'New Password is required';
        } else if (data.Password.length < 8) {
            errors.Password = 'Password must be at least 8 characters';
        } else if (data.Password.length > 12) {
            errors.Password = 'Password should not exceed 12 characters';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,12}$/.test(data.Password)) {
            errors.Password = 'Password should include one uppercase letter, one lowercase letter, numbers and special characters';
        }
        if (!data.confirmpassword) {
            errors.confirmpassword = 'Confirm password is required';
        }
        if (data.confirmpassword !== data.Password) {
            errors.confirmpassword = 'Passwords do not match';
        }
        return errors;
    };

    return (
        <div>
            <Navbar />
            <div class="bg-light py-3 py-md-5">
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 col-xxl-8">
                            <div class="bg-white p-4 p-md-5 rounded shadow-sm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="text-center mb-5">
                                            <Link to="#!">
                                                <img src="https://www.linkurcodes.com/images/logo.png" alt="" width="175" height="57" />
                                            </Link><br /><br />
                                            <h3>Add Admin Staff Details</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gy-3 gy-md-4 overflow-hidden">
                                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label for="" class="form-label">Admin Staff Name <span class="text-danger">*</span></label>
                                        <input onChange={inputHandler} type="text" class="form-control" name="AdStaffName" value={inputField.AdStaffName} id="AdStaffName" />
                                        {errors.AdStaffName && <span style={{ color: 'red' }} className="error">{errors.AdStaffName}</span>}
                                    </div>
                                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label for="" class="form-label">Mobile Number <span class="text-danger">*</span></label>
                                        <input onChange={inputHandler} type="text" class="form-control" name="PhNo" value={inputField.PhNo} id="PhNo" />
                                        {errors.PhNo && <span style={{ color: 'red' }} className="error">{errors.PhNo}</span>}
                                    </div>
                                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <label for="" class="form-label">Address <span class="text-danger">*</span></label>
                                        <textarea onChange={inputHandler} name="Address" id="Address" cols="30" rows="5" className="input form-control" value={inputField.Address} ></textarea>
                                        {errors.Address && <span style={{ color: 'red' }} className="error">{errors.Address}</span>}
                                    </div>
                                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label for="" class="form-label">Aadhar Number <span class="text-danger">*</span></label>
                                        <input onChange={inputHandler} type="text" class="form-control" name="AadharNo" value={inputField.AadharNo} id="AadharNo" />
                                        {errors.AadharNo && <span style={{ color: 'red' }} className="error">{errors.AadharNo}</span>}
                                    </div>
                                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label for="" class="form-label">Email <span class="text-danger">*</span></label>
                                        <input onChange={inputHandler} type="text" class="form-control" name="Email" value={inputField.Email} id="Email" />
                                        {errors.Email && <span style={{ color: 'red' }} className="error">{errors.Email}</span>}
                                    </div>
                                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label for="" class="form-label">Password</label>
                                        <div class="input-group">
                                            <input onChange={inputHandler} type={showPassword ? "text" : "password"} class="form-control" name="Password" value={inputField.Password} id="Password" />
                                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                                                <i className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"} id="togglePassword"></i>
                                            </span>
                                        </div>
                                        {errors.Password && <span style={{ color: 'red' }} className="error">{errors.Password}</span>}
                                    </div>
                                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label for="password" class="form-label">Confirm Password <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <input type={showConfirmPassword ? "text" : "password"} class="form-control" name="confirmpassword" id="confirmpassword" onChange={inputHandler} value={inputField.confirmpassword} />
                                            <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                <i className={showConfirmPassword ? "bi bi-eye" : "bi bi-eye-slash"} id="toggleConfirmPassword"></i>
                                            </span>
                                        </div>
                                        {errors.confirmpassword && <span style={{ color: 'red' }} className="error">{errors.confirmpassword}</span>}
                                    </div>
                                    <div class="col-12">
                                        <div class="d-grid">
                                            <button onClick={handleSubmit} class="btn btn-primary btn-lg" type="submit">Register</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <hr class="mt-5 mb-4 border-secondary-subtle" />
                                        <div class="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center">
                                            &copy; 2024 Link Ur Codes. All rights reserved.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showWaitingModal && (
                <div className="modal show d-block" tabIndex={-1}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                            </div>
                            <div className="modal-body">
                                <>
                                    <div className="mb-3">
                                        <p>Processing Request. Do Not Refresh.</p>
                                    </div>
                                </>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showOverlay && (
                <div
                    className="modal-backdrop fade show"
                    onClick={() => {
                        setShowWaitingModal(false);
                        setShowOverlay(false);
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 1040, // Ensure this is below your modal's z-index
                    }}
                ></div>
            )}
        </div>
    )
}

export default AddAdminStaff