import axios from 'axios'
import React, { useEffect, useState } from 'react'
import '../../config/config'
import { Link, useNavigate } from 'react-router-dom'

const StudentRegistration = () => {

  const [inputField, setInputField] = useState({
    "collegeId": "",
    "batchId": "",
    "studName": "",
    "admNo": "",
    "rollNo": "",
    "studDept": "",
    "course": "",
    "studEmail": "",
    "studPhNo": "",
    "aadharNo": "",
    "password": "",
    "confirmpassword": ""
  })

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({})

  const [file, setFile] = useState(null)

  const [showModal, setShowModal] = useState(false);
  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false); // New state for overlay

  const fileUploadHandler = (event) => {
    setErrors({})
    setFile(event.target.files[0])
  }

  // Call this function to close the modal and navigate
  const closeAndNavigate = () => {
    setShowModal(false); // Set showModal state to false
    setShowOverlay(false);

    // Remove any leftover classes or styles that might be interfering
    document.body.classList.remove('modal-open');
    document.querySelectorAll('.modal-backdrop').forEach(el => el.remove());

    navigate("/studentLogin"); // Navigate after modal is closed and cleaned up
  };

  let [batchAmount, setbatchAmount] = useState(0)

  const [outputField, setOutputField] = useState([])

  const [batches, setBatches] = useState([])

  const [amount, setAmount] = useState(0)

  const [updateField, setUpdateField] = useState({
    "otp": ""
  })


  const apiUrl = global.config.urls.api.server + "/api/lms/studreg"
  const apiUrl2 = global.config.urls.api.server + "/api/lms/studentregviewcollege"
  const batchUrl = global.config.urls.api.server + "/api/lms/studregviewbatch";
  const batchAmountUrl = global.config.urls.api.server + "/api/lms/studregviewbatchamount"
  const sendOtpUrl = global.config.urls.api.server + "/api/lms/studregotpmailsend"
  const verifyOtpUrl = global.config.urls.api.server + "/api/lms/studregotpverify"


  const sendOtp = async (e) => {
    let data = { "admNo": inputField.admNo, "collegeId": inputField.collegeId, "batchId": inputField.batchId, "rollNo": inputField.rollNo, "aadharNo": inputField.aadharNo, "studEmail": inputField.studEmail }
    let axiosConfig = {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
      }
    };
    e.preventDefault();
    const validationErrors = validateForm(inputField);
    if (Object.keys(validationErrors).length === 0) {
      axios.post(sendOtpUrl, data, axiosConfig).then(
        (response) => {
          if (response.data.status === "OTP sent to email.") {
            alert("OTP Send To Your Email")
            setShowModal(true)
            setShowOverlay(true)
          } else {
            alert(response.data.status)
            setShowModal(false)
            setShowOverlay(false)
          }
        }
      )
    } else {
      setErrors(validationErrors);
      setShowModal(false)
      setShowOverlay(false)
    }
  }

  // Function to close modal
  const closeModal = () => {
    setShowModal(false);
    setShowOverlay(false)
    setUpdateField({
      "otp": ""
    });
  };

  const closeWaitingModal = () => {
    setShowOverlay(false)
    setShowWaitingModal(false)
  }

  const getData = () => {
    let axiosConfig = {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
      }
    };
    axios.post(apiUrl2, axiosConfig).then(
      (response) => {
        // Filter out colleges with registrationStatus === 0
        const activeColleges = response.data.data.filter(college => college.registrationStatus === 1);
        setOutputField(activeColleges)
      }
    )
  }


  // Function to fetch batches based on the selected college
  const getBatches = (collegeId) => {
    let axiosConfig = {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
      }
    };
    axios.post(batchUrl, { collegeId }, axiosConfig).then((response) => {
      // Filter out batches with registrationStatus === 0
      const activeBatches = response.data.data.filter(batch => batch.registrationStatus === 1);
      setBatches(activeBatches);
    });
  };

  const getBatchAmount = (batchId) => {
    let axiosConfig = {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
      }
    };
    axios.post(batchAmountUrl, { batchId }, axiosConfig).then(
      (response) => {
        if (response.data.status === "success") {
          let currentAmount = response.data.data;
          if (currentAmount !== batchAmount) {
            setAmount(currentAmount); // Update the state if needed
          }
          setAmount(response.data.data)
          setbatchAmount(response.data.data)
        } else {
          setAmount(0)
          setbatchAmount(0)
        }
      }
    )
  }


  // Call getBatches whenever the college selection changes
  const handleCollegeChange = (event) => {
    const selectedCollegeId = event.target.value;
    setInputField(prevState => ({ ...prevState, collegeId: selectedCollegeId }));
    getBatches(selectedCollegeId);
  };

  const handleBatchChange = (e) => {
    const selectedBatchId = e.target.value;
    setInputField(prevState => ({ ...prevState, batchId: selectedBatchId }));
    getBatchAmount(selectedBatchId)
  }


  const inputHandler = (event) => {
    setErrors({})
    setInputField({ ...inputField, [event.target.name]: event.target.value });
  };

  const updateHandler = (event) => {
    setErrors({})
    setUpdateField({ ...updateField, [event.target.name]: event.target.value });
  };

  const loadRazorpayScript = async () => {
    const script = document.createElement('script')
    script.src = "https://checkout.razorpay.com/v1/checkout.js"

    document.body.appendChild(script)

    script.onload = () => {
      //initialize razorpay
      const rzp = new window.Razorpay({
        key: 'rzp_live_dUnqwXAyfPl7BC',
        amount: batchAmount * 100,
        name: 'Logix Space Technologies Pvt Ltd',
        description: 'Link Ur Codes Payment',
        // image: <img src="https://www.linkurcodes.com/images/logo.png" alt="Company Logo" className="img-fluid" />,
        image: 'https://www.linkurcodes.com/images/logo.png',


        handler: function (response) {

          const PaymentId = response.razorpay_payment_id

          setShowWaitingModal(true)
          setShowOverlay(true)
          // Call Registration API and pass the details to the server
          let data = {
            "collegeId": inputField.collegeId,
            "batchId": inputField.batchId,
            "studName": inputField.studName,
            "admNo": inputField.admNo,
            "rollNo": inputField.rollNo,
            "studDept": inputField.studDept,
            "course": inputField.course,
            "studEmail": inputField.studEmail,
            "studPhNo": inputField.studPhNo,
            "aadharNo": inputField.aadharNo,
            "password": inputField.password,
            "studProfilePic": file,
            "rpPaymentId": PaymentId,
            "rpOrderId": orderId,
            "rpAmount": batchAmount
          };
          let axiosConfig = {
            headers: {
              'content-type': 'multipart/form-data',
            }
          };
          axios.post(apiUrl, data, axiosConfig).then(
            (response) => {
              if (response.data.status === "success") {
                closeWaitingModal()
                setTimeout(() => {
                  alert("User Registered Successfully !!!")
                  closeAndNavigate()
                  setInputField({ "collegeId": "", "batchId": "", "studName": "", "admNo": "", "rollNo": "", "studDept": "", "course": "", "studEmail": "", "studPhNo": "", "studProfilePic": "", "aadharNo": "", "password": "", "confirmpassword": "" })
                }, 500)
              } else if (response.data.status === "Validation failed" && response.data.data.college) {
                alert(response.data.data.college)
              } else if (response.data.status === "Validation failed" && response.data.data.batch) {
                alert(response.data.data.batch)
              } else if (response.data.status === "Validation failed" && response.data.data.name) {
                alert(response.data.data.name)
              } else if (response.data.status === "Validation failed" && response.data.data.admNo) {
                alert(response.data.data.admNo)
              } else if (response.data.status === "Validation failed" && response.data.data.rollNo) {
                alert(response.data.data.rollNo)
              } else if (response.data.status === "Validation failed" && response.data.data.department) {
                alert(response.data.data.department)
              } else if (response.data.status === "Validation failed" && response.data.data.course) {
                alert(response.data.data.course)
              } else if (response.data.status === "Validation failed" && response.data.data.email) {
                alert(response.data.data.email)
              } else if (response.data.status === "Validation failed" && response.data.data.phone) {
                alert(response.data.data.phone)
              } else if (response.data.status === "Validation failed" && response.data.data.aadharNo) {
                alert(response.data.data.aadharNo)
              } else if (response.data.status === "Validation failed" && response.data.data.password) {
                alert(response.data.data.password)
              } else {
                closeWaitingModal()
                setTimeout(() => {
                  alert(response.data.data)
                }, 500)
              }
            }
          )
        }
      })
      function GFG_Fun() {
        return Math.random().toString(36).slice(2)
      }
      const orderId = GFG_Fun()
      rzp.open({
        order_id: orderId
      })
    }
  }


  const handleSubmit = () => {
    let data = { "studEmail": inputField.studEmail, "otp": updateField.otp };
    let axiosConfig = {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
      }
    };
    axios.post(verifyOtpUrl, data, axiosConfig).then(
      (response) => {
        if (response.data.status === "OTP verified successfully" && batchAmount > 0) {
          setShowModal(false)
          setShowOverlay(false)
          loadRazorpayScript()
          setUpdateField({ "otp": "" })
        } else if (response.data.status === "OTP verified successfully" && batchAmount === 0) {
          setShowModal(false)
          setShowOverlay(false)
          setUpdateField({ "otp": "" })
          setShowWaitingModal(true)
          setShowOverlay(true)
          // Call Registration API and pass the details to the server
          let data = {
            "collegeId": inputField.collegeId,
            "batchId": inputField.batchId,
            "studName": inputField.studName,
            "admNo": inputField.admNo,
            "rollNo": inputField.rollNo,
            "studDept": inputField.studDept,
            "course": inputField.course,
            "studEmail": inputField.studEmail,
            "studPhNo": inputField.studPhNo,
            "aadharNo": inputField.aadharNo,
            "password": inputField.password,
            "studProfilePic": file,
            "rpPaymentId": "default",
            "rpOrderId": "default",
            "rpAmount": batchAmount
          };
          let axiosConfig = {
            headers: {
              'content-type': 'multipart/form-data',
            }
          };
          axios.post(apiUrl, data, axiosConfig).then(
            (response) => {
              if (response.data.status === "success") {
                closeWaitingModal()
                setTimeout(() => {
                  alert("User Registered Successfully !!!")
                  closeAndNavigate()
                  setInputField({ "collegeId": "", "batchId": "", "studName": "", "admNo": "", "rollNo": "", "studDept": "", "course": "", "studEmail": "", "studPhNo": "", "studProfilePic": "", "aadharNo": "", "password": "", "confirmpassword": "" })
                }, 500)
              } else if (response.data.status === "Validation failed" && response.data.data.college) {
                alert(response.data.data.college)
              } else if (response.data.status === "Validation failed" && response.data.data.batch) {
                alert(response.data.data.batch)
              } else if (response.data.status === "Validation failed" && response.data.data.name) {
                alert(response.data.data.name)
              } else if (response.data.status === "Validation failed" && response.data.data.admNo) {
                alert(response.data.data.admNo)
              } else if (response.data.status === "Validation failed" && response.data.data.rollNo) {
                alert(response.data.data.rollNo)
              } else if (response.data.status === "Validation failed" && response.data.data.department) {
                alert(response.data.data.department)
              } else if (response.data.status === "Validation failed" && response.data.data.course) {
                alert(response.data.data.course)
              } else if (response.data.status === "Validation failed" && response.data.data.email) {
                alert(response.data.data.email)
              } else if (response.data.status === "Validation failed" && response.data.data.phone) {
                alert(response.data.data.phone)
              } else if (response.data.status === "Validation failed" && response.data.data.aadharNo) {
                alert(response.data.data.aadharNo)
              } else if (response.data.status === "Validation failed" && response.data.data.password) {
                alert(response.data.data.password)
              } else {
                closeWaitingModal()
                setTimeout(() => {
                  alert(response.data.data)
                }, 500)
              }
            }
          )

        } else if (response.data.status === "Invalid OTP") {
          alert("Invalid OTP")
          setUpdateField({ "otp": "" })
          setShowModal(true);
          setShowOverlay(true);
        } else {
          alert(response.data.status)
          setUpdateField({ "otp": "" })
          setShowModal(false); // Reset showModal state
          setShowOverlay(false);
        }
      }
    )
  };




  const validateForm = (data) => {
    let errors = {};

    if (!data.collegeId.trim()) {
      errors.collegeId = 'College is required';
    }

    if (!data.batchId.trim()) {
      errors.batchId = 'Batch is required';
    }
    if (!data.studName.trim()) {
      errors.studName = 'Name is required';
    } else if (!/^[a-zA-Z\s]*$/.test(data.studName)) {
      errors.studName = 'Only Letters Are Allowed In "Name" Field. Special Characters Are Not Allowed.';
    }
    if (!data.admNo.trim()) {
      errors.admNo = 'Admission number is required';
    }
    if (!data.rollNo.trim()) {
      errors.rollNo = 'Roll number is required';
    }
    if (!data.course.trim()) {
      errors.course = 'Course is required';
    }
    if (!data.studDept.trim()) {
      errors.studDept = 'Department is required';
    }
    if (!data.confirmpassword) {
      errors.confirmpassword = 'Confirm password is required';
    }
    if (!file) {
      errors.studProfilePic = 'Profile Image is required';
    } else {
      // Check if file is an image
      const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/heif'];
      if (!validImageTypes.includes(file.type)) {
        errors.studProfilePic = 'Profile Image must be a valid image file (JPEG, PNG, JPG, WEBP, HEIF)';
      } else if (file.size > 10 * 1024 * 1024) { // 10 MB in bytes
        errors.studProfilePic = 'Profile Image size should be less than 10 MB';
      }
    }
    if (!data.studPhNo.trim()) {
      errors.studPhNo = 'Phone No is required';
    } else if (!/^\+91[6-9]\d{9}$|^\+91\s?[6-9]\d{9}$|^[6-9]\d{9}$/.test(data.studPhNo)) {
      errors.studPhNo = 'Invalid phone number';
    }

    if (!data.aadharNo.trim()) {
      errors.aadharNo = 'Aadhar No is required';
    } else if (!/^\d{12}$/.test(data.aadharNo)) {
      errors.aadharNo = 'Invalid Aadhar number';
    }


    if (!data.studEmail.trim()) {
      errors.studEmail = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.studEmail)) {
      errors.studEmail = 'Invalid email address';
    }

    if (!data.password.trim()) {
      errors.password = 'Password is required';
    } else if (data.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,12}$/.test(data.password)) {
      errors.password = 'Password must be at least 8 characters and should not exceed 12 characters and should include one uppercase letter, one lowercase letter, numbers and special characters';
    }

    if (data.confirmpassword !== data.password) {
      errors.confirmpassword = 'Passwords do not match';
    }
    return errors;
  };

  useEffect(() => { getData() }, [])

  // Update key state when component mounts
  useEffect(() => {
    setAmount(batchAmount || '');
  }, []);

  return (
    <div className="bg-light py-3 py-md-5">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
            <div className="bg-white p-4 p-md-5 rounded shadow-sm">
              <div className="row">
                <div className="col-12">
                  <div className="text-center mb-5">
                    <Link to="#!">
                      <img
                        src="https://www.linkurcodes.com/images/logo.png"
                        alt=""
                        width="175"
                        height="57" />
                    </Link>
                    <br />
                    <br />
                    <h3>Student Registration</h3>
                  </div>
                </div>
              </div>
              <div className="row gy-3 gy-md-4 overflow-hidden">
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="" className="form-label">
                    College Name <span className="text-danger">*</span>
                  </label>
                  <select
                    name="collegeId"
                    value={inputField.collegeId}
                    onChange={handleCollegeChange}
                    id="collegeId"
                    className="form-control">
                    <option value="">Select</option>
                    {outputField.map((value) => {
                      return <option value={value.id}> {value.collegeName} </option>
                    })}
                  </select>
                  {errors.collegeId && <span style={{ color: 'red' }} className="error">{errors.collegeId}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="batchId" className="form-label">
                    Batch Name <span className="text-danger">*</span>
                  </label>
                  <select
                    name="batchId"
                    id="batchId"
                    className="form-control"
                    value={inputField.batchId}
                    onChange={handleBatchChange}>
                    <option value="">Select</option>
                    {batches && batches.map((value) => {
                      return <option key={value.id} value={value.id}> {value.batchName} </option>;
                    })}
                  </select>
                  {errors.batchId && <span style={{ color: 'red' }} className="error">{errors.batchId}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="studName" className="form-label">
                    Student Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="studName"
                    value={inputField.studName} onChange={inputHandler}
                    id="studName" />
                  {errors.studName && <span style={{ color: 'red' }} className="error">{errors.studName}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="admNo" className="form-label">
                    Admission No <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="admNo"
                    value={inputField.admNo} onChange={inputHandler}
                    id="admNo" />
                  {errors.admNo && <span style={{ color: 'red' }} className="error">{errors.admNo}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="rollNo" className="form-label">
                    Roll No <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="rollNo"
                    value={inputField.rollNo} onChange={inputHandler}
                    id="rollNo" />
                  {errors.rollNo && <span style={{ color: 'red' }} className="error">{errors.rollNo}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="studDept" className="form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="studDept"
                    value={inputField.studDept} onChange={inputHandler}
                    id="studDept" />
                  {errors.studDept && <span style={{ color: 'red' }} className="error">{errors.studDept}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="course" className="form-label">
                    Course <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="course"
                    value={inputField.course} onChange={inputHandler}
                    id="course" />
                  {errors.course && <span style={{ color: 'red' }} className="error">{errors.course}</span>}
                </div>

                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="studEmail" className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="studEmail"
                    value={inputField.studEmail} onChange={inputHandler}
                    id="studEmail" />
                  {errors.studEmail && <span style={{ color: 'red' }} className="error">{errors.studEmail}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="studPhNo" className="form-label">
                    Phone No <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required=""
                    name="studPhNo"
                    id="studPhNo"
                    value={inputField.studPhNo} onChange={inputHandler} />
                  {errors.studPhNo && <span style={{ color: 'red' }} className="error">{errors.studPhNo}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="studProfilePic" className="form-label">
                    Profile Image <span className="text-danger">*</span>
                  </label>
                  <input type="file" className="form-control" name="studProfilePic" id="studProfilePic" accept="image/*" onChange={fileUploadHandler} />
                  {errors.studProfilePic && <span style={{ color: 'red' }} className="error">{errors.studProfilePic}</span>}
                </div>
                <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <label htmlFor="aadharNo" className="form-label">
                    Aadhar No <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="aadharNo"
                    value={inputField.aadharNo} onChange={inputHandler}
                    id="aadharNo" />
                  {errors.aadharNo && <span style={{ color: 'red' }} className="error">{errors.aadharNo}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="password" className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={inputField.password} onChange={inputHandler}
                      id="password" />
                    <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                      <i className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"} id="togglePassword"></i>
                    </span><br />
                  </div>
                  {errors.password && <span style={{ color: 'red' }} className="error">{errors.password}</span>}
                </div>
                <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <label htmlFor="password" className="form-label">Confirm Password <span className="text-danger">*</span></label>
                  <div className="input-group">
                    <input type={showConfirmPassword ? "text" : "password"} className="form-control" name="confirmpassword" id="confirmpassword" onChange={inputHandler} value={inputField.confirmpassword} />
                    <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      <i className={showConfirmPassword ? "bi bi-eye" : "bi bi-eye-slash"} id="toggleConfirmPassword"></i>
                    </span><br />
                  </div>
                  {errors.confirmpassword && <span style={{ color: 'red' }} className="error">{errors.confirmpassword}</span>}
                </div>
                <div className="col-12">
                  {amount === batchAmount && (
                    <button type="button" onClick={sendOtp} className="btn btn-primary">Register</button>
                  )}
                </div>
              </div>
              <div>
                {showModal && (
                  <div className="modal show d-block" tabIndex={-1}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel">Verify Email</h1>
                          <button type="button" className="btn-close" onClick={closeModal} />
                        </div>
                        <div className="modal-body">
                          <>
                            <div className="mb-3">
                              <label htmlFor="message-text" className="col-form-label">OTP:</label>
                              <input type="text" onChange={updateHandler} value={updateField.otp} name="otp" className="form-control" id="message-text" defaultValue={""} />
                            </div>
                          </>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                          <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showWaitingModal && (
                  <div className="modal show d-block" tabIndex={-1}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                        </div>
                        <div className="modal-body">
                          <>
                            <div className="mb-3">
                              <p>Processing Request. Do Not Refresh.</p>
                            </div>
                          </>
                        </div>
                        <div className="modal-footer">
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showOverlay && (
                  <div
                    className="modal-backdrop fade show"
                    onClick={() => {
                      setShowModal(false);
                      setShowOverlay(false);
                    }}
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 1040, // Ensure this is below your modal's z-index
                    }}
                  ></div>
                )}

              </div>
              <br />
              <div className="row gy-3 gy-md-4 overflow-hidden">
                <p style={{ textAlign: "center" }}>Already have an account? <Link to="/studentLogin">Sign In</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default StudentRegistration