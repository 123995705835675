import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../../../config/config'

const StudViewProfile = () => {
    const [studData, setStudData] = useState([])
    const [performanceData, setPerformanceData] = useState([])
    const apiURL = global.config.urls.api.server + "/api/lms/studentViewProfile"
    const performApiURL = global.config.urls.api.server + "/api/lms/viewPerformancebyEachStud"

    const navigate = useNavigate()

    const logOut = () => {
        sessionStorage.clear()
    }

    const getData = () => {
        let data = { "studId": sessionStorage.getItem("studentId") }
        let axiosConfig = {
            headers: {
                "content-type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": sessionStorage.getItem("studLoginToken"),
                "key": sessionStorage.getItem("studentkey")
            }
        }
        axios.post(apiURL, data, axiosConfig).then(
            (response) => {
                if (response.data.data) {
                    setStudData(response.data.data)
                } else {
                    if (response.data.status === "Unauthorized User!!") {
                        logOut()
                        navigate("/studentLogin")
                    } else {
                        alert(response.data.status)
                    }
                }
            }
        )
    }

    const getPerformanceData = () => {
        let performData = {
            "id": sessionStorage.getItem("studentId"),
            "collegeId": sessionStorage.getItem("studCollegeId"),
            "batchId": sessionStorage.getItem("studBatchId")
        }
        let axiosConfig = {
            headers: {
                "content-type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": sessionStorage.getItem("studLoginToken"),
                "key": sessionStorage.getItem("studentkey")
            }
        }
        axios.post(performApiURL, performData, axiosConfig).then(
            (response) => {
                if (response.data.data) {
                    setPerformanceData(response.data.data)
                } else if (!response.data.data) {
                    setPerformanceData(response.data.data)
                } else {
                    if (response.data.status === "Unauthorized User!!") {
                        logOut()
                        navigate("/studentLogin")
                    } else {
                        alert(response.data.status)
                    }
                }
            }
        )
    }

    const UpdateClick = () => {
        navigate("/studValidityRenewal");
    };

    const isRenewalDue = (validityDate) => {
        const currentDate = new Date();
        // Parse the validity date in DD/MM/YYYY format
        const [day, month, year] = validityDate.split('/');
        const parsedValidityDate = new Date(`${year}-${month}-${day}`);

        const differenceInMilliseconds = parsedValidityDate - currentDate;
        const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);
        return differenceInDays <= 45;
    };

    // Function to generate star icons based on dev rating value
    const renderStarRating = (rating) => {
        const fullStars = Math.floor(rating);
        const remainder = rating - fullStars;
        const stars = [];

        // Render full stars
        for (let i = 0; i < fullStars; i++) {
            stars.push(<i className="bi bi-star-fill" key={i}></i>);
        }

        // Render partially filled star if remainder is greater than 0
        if (remainder > 0) {
            // Calculate width of partially filled star
            const partialStarWidth = `${Math.round(remainder * 100)}%`;
            stars.push(
                <i className="bi bi-star-half" key={fullStars} style={{ width: partialStarWidth }}></i>
            );
        }

        // Render empty stars to fill up to 5 stars
        const remainingEmptyStars = 5 - fullStars - (remainder > 0 ? 1 : 0);
        for (let i = 0; i < remainingEmptyStars; i++) {
            stars.push(<i className="bi bi-star" key={fullStars + (remainder > 0 ? 1 : 0) + i}></i>);
        }

        return stars;
    };



    useEffect(() => { getData() }, [])

    useEffect(() => { getPerformanceData() }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 mb-4 mb-sm-5">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div className="card card-style1 --bs-primary-border-subtle border-5">
                        <div className="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                            {studData.map(
                                (value, index) => {
                                    return <div className="row align-items-center">
                                        <div className="col-lg-6 mb-4 mb-lg-0">
                                            <div className="row">
                                                <img height="300px" src={value.studProfilePic} alt="" />
                                            </div><br />
                                            <div className="row justify-content-center">
                                                <div className="col-lg-8">
                                                    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Your Performance</h5>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <img style={{ height: '100px', width: "150px" }} src="https://www.svgrepo.com/show/423006/trophy-prize-medal.svg" alt />
                                                            </div>
                                                            <div className="col-6">
                                                                <div>
                                                                    <p style={{ textAlign: 'center', fontSize: '16px' }} className="mt-10 font-bold text-gray-700 dark:text-gray-400">
                                                                        Dev Rating : {performanceData.cgpa && performanceData.cgpa > 0 ? performanceData.cgpa.toFixed(1) : performanceData.cgpa}/{performanceData.cgpaMax}
                                                                    </p>
                                                                    {/* Render star rating */}
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        {renderStarRating(performanceData.cgpa)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {performanceData.cgpa >= 4 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">Excellent...😍🤩🎉👏</p>
                                                                )}
                                                                {performanceData.cgpa >= 3 && performanceData.cgpa < 4 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">Good Job...👍👍</p>
                                                                )}
                                                                {performanceData.cgpa >= 2 && performanceData.cgpa < 3 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">Good 👍...You can improve more!!</p>
                                                                )}
                                                                {performanceData.cgpa >= 1 && performanceData.cgpa < 2 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">Very Poor😢...Need to improve. You can do this!!😀💪</p>
                                                                )}
                                                                {performanceData.cgpa < 1 && performanceData.cgpa > 0 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">Very Poor😢...Need to improve. You can do this!!😀💪</p>
                                                                )}
                                                                {performanceData.cgpa === 0 && performanceData.SubmitTaskCount > 0 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">Task Submitted but yet to be Evaluated...</p>
                                                                )}
                                                                {performanceData.cgpa === 0 && performanceData.SubmitTaskCount === 0 && performanceData.TaskCount > 0 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">❌ No Tasks Completed!!! ❌</p>
                                                                )}
                                                                {performanceData.cgpa === 0 && performanceData.SubmitTaskCount === 0 && performanceData.TaskCount === 0 && (
                                                                    <p style={{ textAlign: 'center' }} className="mb-3 font-normal text-gray-700 dark:text-gray-400">No Tasks Assigned!!!</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 px-xl-10">
                                            <div className="d-lg-flex justify-between align-items-center py-1-9 px-1-9 px-sm-6 mb-1-9 rounded">
                                                <div className="d-lg-inline-block">
                                                    <h3 className="h2 text-black mb-0">{value.studName}</h3>
                                                    <br></br>
                                                </div>
                                                <div className="d-lg-inline-block">
                                                    {value.validity && isRenewalDue(value.validity) && (
                                                        <button onClick={() => { UpdateClick() }} className="btn bg-blue-500 text-white px-4 py-2 rounded-md">Renew</button>
                                                    )}
                                                </div>
                                            </div>
                                            <ul className="list-unstyled mb-1-9">
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">College Name : {value.collegeName}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Batch : {value.batchName}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Membership No. : {value.membership_no}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Admission No. : {value.admNo}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Roll No. : {value.rollNo}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Aadhar Number : {value.aadharNo}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Department : {value.studDept}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Course : {value.course}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Phone Number : {value.studPhNo}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Email : {value.studEmail}</span></li>
                                                <li className="mb-2 mb-xl-3 display-28"><span className="display-26 text-secondary me-2 font-weight-600">Profile Validity : {value.validity.split('-').reverse().join('/')}</span></li>

                                                <div className="flex justify-between items-center mb-3">
                                                    <div style={{ marginRight: '15px', marginBottom: '10px' }}>
                                                        <Link className="btn btn-success btn-lg" to='/studentupdateprofile' style={{ fontSize: '14px' }}>Edit Profile</Link> {/* Adjust fontSize as needed */}
                                                    </div>
                                                    <div style={{ marginRight: '15px', marginBottom: '10px' }}>
                                                        <Link className="btn btn-success btn-lg" to='/studentviewtransaction' style={{ fontSize: '14px' }}>View Transaction Details</Link> {/* Adjust fontSize as needed */}
                                                    </div>
                                                    <div style={{ marginRight: '15px', marginBottom: '10px' }}>
                                                        <Link className="btn btn-success btn-lg" to='/studviewperformance' style={{ fontSize: '14px' }}>View Overall Performance</Link> {/* Adjust fontSize as needed */}
                                                    </div>
                                                </div>


                                            </ul>
                                            <ul className="social-icon-style1 list-unstyled mb-0 ps-0">
                                                <li><a href="#!"><i className="ti-twitter-alt" /></a></li>
                                                <li><a href="#!"><i className="ti-facebook" /></a></li>
                                                <li><a href="#!"><i className="ti-pinterest" /></a></li>
                                                <li><a href="#!"><i className="ti-instagram" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default StudViewProfile
