import React, { useEffect, useState } from 'react';
import '../../config/config';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdmStaffNavBar from './AdmStaffNavBar';
import Navbar from '../Admin/Navbar';

const AdminStaffViewAllMaterial = () => {
    const [inputField, setInputField] = useState({
        materialQuery: "",
    });
    const [materialData, setMaterialData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [materialPerPage] = useState(10);
    const [deleteId, setDeleteId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false); // New state for overlay
    const [key, setKey] = useState('');


    const rangeSize = 5; // Number of pages to display in the pagination
    const lastPage = Math.ceil(materialData.length / materialPerPage); // Calculate the total number of pages
    let startPage = Math.floor((currentPage - 1) / rangeSize) * rangeSize + 1; // Calculate the starting page for the current range
    let endPage = Math.min(startPage + rangeSize - 1, lastPage); // Calculate the ending page for the current range

    const apiUrl = global.config.urls.api.server + "/api/lms/AdmViewAllMaterial";
    const apiLink = global.config.urls.api.server + "/api/lms/searchMaterial";
    const apiLink2 = global.config.urls.api.server + "/api/lms/adminStaffDeleteMaterial";
    const navigate = useNavigate();

    const inputHandler = (event) => {
        setInputField({ ...inputField, [event.target.name]: event.target.value });
    };

    const closeWaitingModal = () => {
        setShowOverlay(false)
        setShowWaitingModal(false)
    }

    const getData = () => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Token": token,
                "Key": currentKey
            }
        };
        axios.post(apiUrl, {}, axiosConfig).then((response) => {
            if (response.data.data) {
                setIsLoading(false)
                setMaterialData(response.data.data);
            } else if (response.data.status === "Unauthorized User!!") {
                { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                sessionStorage.clear();
            } else if (!response.data.data) {
                setIsLoading(false)
                setMaterialData([]);
            } else {
                setIsLoading(false)
                alert(response.data.status);
            }

        });
    };

    const readValue = () => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        setIsLoading(true);
        let axiosConfig = {
            headers: {
                "content-type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "token": token,
                "key": currentKey
            },
        };

        axios.post(apiLink, inputField, axiosConfig).then((response) => {
            if (response.data.status === "Provide a search query") {
                setIsLoading(false)
                setTimeout(() => {
                    alert(response.data.status)
                    getData()
                }, 500)
            } else if (response.data.data) {
                setMaterialData(response.data.data);
                setIsLoading(false);
                setInputField({
                    materialQuery: "",
                });
            } else if (response.data.status === "Unauthorized User!!") {
                { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                sessionStorage.clear();
            } else if (!response.data.data) {
                setIsLoading(false);
                setInputField({
                    materialQuery: "",
                });
                setTimeout(() => {
                    alert("No Materials Found")
                    getData()
                }, 500)
            } else {
                alert(response.data.status);
            }
        });
    };

    const updateClick = (id) => {
        let data = id;
        sessionStorage.setItem("materialId", data);
    };

    const handleClick = (id) => {
        setDeleteId(id);
    };

    const handleDeleteClick = () => {
        let data = { "id": deleteId, "addedby": sessionStorage.getItem("admstaffId") };
        let axiosConfig2 = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "token": sessionStorage.getItem("admtoken"),
                "key": sessionStorage.getItem("admkey")
            }
        };
        setShowWaitingModal(true)
        setShowOverlay(true)
        axios.post(apiLink2, data, axiosConfig2).then((response) => {
            if (response.data.status === "Material Deleted Successfully.") {
                closeWaitingModal()
                setTimeout(() => {
                    alert("Material Deleted Successfully.")
                    // Remove the deleted material from updateField state
                    setMaterialData(materialData.filter(material => material.id !== deleteId));
                }, 500)
            } else if (response.data.status === "Unauthorized User!!") {
                navigate("/")
                sessionStorage.clear()
            } else {
                closeWaitingModal()
                setTimeout(() => {
                    alert(response.data.status);
                }, 500)
            }
        });
    };

    // Logic for displaying current curriculum
    const indexOfLastMaterial = currentPage * materialPerPage;
    const indexOfFirstMaterial = indexOfLastMaterial - materialPerPage;
    const currentMaterial = materialData.length > 0 ? materialData.slice(indexOfFirstMaterial, indexOfLastMaterial) : [];

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(materialData.length / materialPerPage);

    const calculateSerialNumber = (index) => {
        return ((currentPage - 1) * materialPerPage) + index + 1;
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <div>
            {key === 'lmsappadmstaff' ? <AdmStaffNavBar /> : <Navbar />}
            <div className="flex justify-between items-center mx-4 my-4">
                <button onClick={() => navigate(-1)} className="btn bg-gray-500 text-white px-4 py-2 rounded-md">Back</button>

                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>View All Materials</p>

                <div></div>
            </div>
            <div className="flex justify-between items-center mx-4 my-4">
                <div className="container">
                    <div className="row g-3">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <label htmlFor="" className="form-label"></label>
                            <input onChange={inputHandler} type="text" className="form-control" name="materialQuery" value={inputField.materialQuery} placeholder='Search By College Name/Batch Name/File Name/Description' />
                        </div>
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <button onClick={readValue} className="btn btn-warning">Search</button>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <div className="flex justify-center items-center h-full">
                <div className="text-center py-20">
                    <div>Loading...</div>
                </div>
            </div> : (<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {currentMaterial.length > 0 ? (
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">S/N</th>
                                <th scope="col" className="px-6 py-3">College Name</th>
                                <th scope="col" className="px-6 py-3">Batch Name</th>
                                <th scope="col" className="px-6 py-3">File Name</th>
                                <th scope="col" className="px-6 py-3">Material Description</th>
                                <th scope="col" className="px-6 py-3">Remark</th>
                                <th scope="col" className="px-6 py-3">Material Type</th>
                                <th scope="col" className="px-6 py-3">Added Date</th>
                                <th scope="col" className="px-6 py-3"></th>
                                <th scope="col" className="px-6 py-3"></th>
                                {key === "lmsapp" && <th scope="col" className="px-6 py-3"></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {currentMaterial.map((value, index) => (
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={index}>
                                    <td className="px-6 py-4">{calculateSerialNumber(index)}</td>
                                    <td className="px-6 py-4">{value.collegeName}</td>
                                    <td className="px-6 py-4">{value.batchName}</td>
                                    <td className="px-6 py-4">{value.fileName}</td>
                                    <td className="px-6 py-4">{value.materialDesc}</td>
                                    <td className="px-6 py-4">{value.remarks}</td>
                                    <td className="px-6 py-4">{value.materialType}</td>
                                    <td className="px-6 py-4">{value.addedDate}</td>
                                    <td className="px-6 py-4">
                                        <Link target="_blank" to={value.uploadFile} className="btn bg-blue-500 text-white px-4 py-2 rounded-md">View Material</Link>
                                    </td>
                                    <td className="px-6 py-4">
                                        <Link to="/AdminStaffUpdateMaterial" onClick={() => { updateClick(value.id); }} className="btn btn-success">Update</Link>
                                    </td>
                                    {key === "lmsapp" && (
                                        <td className="px-6 py-4">
                                            <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal4" onClick={() => handleClick(value.id)}>
                                                Delete
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <table>
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4" colSpan="7" style={{ textAlign: "center" }}>No Materials Found !!</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                {currentMaterial.length > 0 && (
                    <div className="flex items-center justify-between bg-white px-6 py-4 sm:px-6">
                        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                            <div>
                                <p className="text-sm text-gray-700">
                                    Showing <span className="font-medium">{indexOfFirstMaterial + 1}</span> to <span className="font-medium">{indexOfLastMaterial > materialData.length ? materialData.length : indexOfLastMaterial}</span> of <span className="font-medium">{materialData.length}</span> results
                                </p>
                            </div>
                            <div>
                                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                    <button onClick={() => currentPage > 1 && paginate(currentPage - 1)} className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage === 1 ? 'cursor-not-allowed text-gray-500' : 'text-gray-700 hover:bg-gray-50'} disabled:opacity-50`} disabled={currentPage === 1}>
                                        <span className="sr-only">Previous</span>
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    {/* Dynamically generate Link components for each page number */}
                                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                                        <button key={startPage + index} onClick={() => paginate(startPage + index)} className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === startPage + index ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:bg-gray-50'}`}>
                                            {startPage + index}
                                        </button>
                                    ))}
                                    <button onClick={() => currentPage < totalPages && paginate(currentPage + 1)} className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage === totalPages ? 'cursor-not-allowed text-gray-500' : 'text-gray-700 hover:bg-gray-50'} disabled:opacity-50`} disabled={currentPage === totalPages}>
                                        <span className="sr-only">Next</span>
                                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                )}
            </div>)}

            {/* Delete Confirmation Modal */}
            <div className="modal fade" id="deleteConfirmationModal4" tabIndex="-1" aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteConfirmationModalLabel">Delete Confirmation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this Material?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleDeleteClick}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            {showWaitingModal && (
                <div className="modal show d-block" tabIndex={-1}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                            </div>
                            <div className="modal-body">
                                <>
                                    <div className="mb-3">
                                        <p>Processing Request. Do Not Refresh.</p>
                                    </div>
                                </>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showOverlay && (
                <div
                    className="modal-backdrop fade show"
                    onClick={() => {
                        setShowWaitingModal(false);
                        setShowOverlay(false);
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 1040, // Ensure this is below your modal's z-index
                    }}
                ></div>
            )}
        </div>
    );
};

export default AdminStaffViewAllMaterial;
