import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import '../../config/config';
import axios from 'axios';
import AdmStaffNavBar from '../AdminStaff/AdmStaffNavBar';
import { useNavigate } from 'react-router-dom';

const AdminSendNotification = () => {

    const initialNotificationData = {
        "collegeId": "",
        "batchId": "",
        "message": "",
        "sendby": "",
        "title": ""
    };

    const [notificationData, setNotificationData] = useState(initialNotificationData);
    const [outputField, setOutputField] = useState([])
    const [batches, setBatches] = useState([])
    const [key, setKey] = useState('');
    const navigate = useNavigate()
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false); // New state for overlay

    const apiUrl2 = global.config.urls.api.server + "/api/lms/viewallcolleges";
    const batchUrl = global.config.urls.api.server + "/api/lms/adminviewbatch";
    const apiUrl = global.config.urls.api.server + "/api/lms/sendNotification";

    const handleChange = (event) => {
        setNotificationData({ ...notificationData, [event.target.name]: event.target.value });
    };

    const closeWaitingModal = () => {
        setShowOverlay(false)
        setShowWaitingModal(false)
    }

    const getData = () => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig2 = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                "token": token,
                "key": currentKey
            }
        };
        axios.post(apiUrl2, {}, axiosConfig2).then(
            (response) => {
                if (response.data.data) {
                    setOutputField(response.data.data)
                } else {
                    if (response.data.status === "Unauthorized User!!") {
                        { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                        sessionStorage.clear()
                    } else {
                        if (!response.data.data) {
                            setOutputField([])
                        } else {
                            alert(response.data.status)
                        }
                    }
                }
            }
        )
    }

    const getBatches = (collegeId) => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig3 = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                "token": token,
                "key": currentKey
            }
        };
        axios.post(batchUrl, { collegeId }, axiosConfig3).then((response) => {
            if (response.data) {
                setBatches(response.data)
            } else {
                if (response.data.status === "Unauthorized User!!") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear()
                } else {
                    if (!response.data) {
                        setBatches([])
                    } else {
                        alert(response.data.status)
                    }
                }
            }
        })
    }

    const handleCollegeChange = (event) => {
        const selectedCollegeId = event.target.value;
        setNotificationData(prevState => ({ ...prevState, collegeId: selectedCollegeId }))
        getBatches(selectedCollegeId);
    }

    const handleSubmit = async (event) => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let sendby;
        if (currentKey === 'lmsapp') {
            sendby = 0;
        } else {
            sendby = sessionStorage.getItem("admstaffId");
        }
        event.preventDefault();
        const axiosConfig = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "token": token,
                "key": currentKey
            }
        };
        let data = {
            "batchId": notificationData.batchId,
            "message": notificationData.message,
            "sendby": sendby,
            "title": notificationData.title
        };
        setShowWaitingModal(true);
        setShowOverlay(true);
        try {
            const response = await axios.post(apiUrl, data, axiosConfig);
            if (response.data.status === 'Validation failed') {
                closeWaitingModal();
                setTimeout(() => {
                    const errorMessage = Object.values(response.data.data)[0]; // Extracting the first validation error message
                    alert(errorMessage); // Alerting the validation error message
                }, 500)
            } else if (response.data.status === 'Success') {
                closeWaitingModal();
                setTimeout(() => {
                    alert(response.data.message);
                    // Reset the text fields to their initial empty state
                    setNotificationData(initialNotificationData);
                }, 500);
            } else {
                if (response.data.message === "Invalid token") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear();
                } else {
                    closeWaitingModal();
                    setTimeout(() => {
                        alert(response.data.message);
                    }, 500);
                }
            }
        } catch (error) {
            closeWaitingModal();
            setTimeout(() => {
                alert(error.message);
            }, 500);
        }
    };


    // Inline styles
    const styles = {
        container: {
            textAlign: 'center',
            margin: '20px',
        },
        card: {
            border: '1px solid #ddd',
            borderRadius: '5px',
            padding: '20px',
            maxWidth: '400px',
            margin: '0 auto',
        },
        input: {
            margin: '5px 0',
            padding: '10px',
            width: '100%',
            borderRadius: '5px',
            border: '1px solid #ddd'
        },
        textarea: {
            margin: '5px 0',
            padding: '10px',
            width: '100%',
            height: '100px',
            borderRadius: '5px',
            border: '1px solid #ddd'
        },
        label: {
            display: 'block',
            margin: '10px 0 5px 0',
        },
        button: {
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '20px',
            width: '100%',
        },
        errorMessage: {
            color: 'red',
            margin: '10px 0',
        },
        successMessage: {
            color: 'green',
            margin: '10px 0',
        }
    };

    useEffect(() => { getData() }, [])

    return (
        <div>
            {key === 'lmsappadmstaff' ? <AdmStaffNavBar /> : <Navbar />}
            <div style={styles.container}>

                <br />
                <h2>Send Notification</h2>
                <br />
                <div style={styles.card}>
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label htmlFor="collegeId" className="form-label">
                            College Name <span className="text-danger">*</span>
                        </label>
                        <select
                            name="collegeId"
                            value={notificationData.collegeId}
                            onChange={handleCollegeChange}
                            id="collegeId"
                            className="form-control">
                            <option value="">Select</option>
                            {outputField.map((value) => {
                                return <option value={value.id}> {value.collegeName} </option>
                            })}
                        </select>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <label htmlFor="batchName" className="form-label">
                            Batch Name <span className="text-danger">*</span>
                        </label>
                        <select
                            name="batchId"
                            id="batchId"
                            className="form-control"
                            value={notificationData.batchId}
                            onChange={handleChange}>
                            <option value="">Select</option>
                            {batches.data && batches.data.map((value) => {
                                return <option value={value.id}> {value.batchName} </option>;
                            })}
                        </select>
                    </div>
                    <div>
                        <label style={styles.label}>Title:</label>
                        <input
                            type="text"
                            name="title"
                            value={notificationData.title}
                            onChange={handleChange}
                            style={styles.input}
                        />
                    </div>
                    <div>
                        <label style={styles.label}>Message:</label>
                        <textarea
                            name="message"
                            value={notificationData.message}
                            onChange={handleChange}
                            style={styles.textarea}
                        />
                    </div>
                    <button type="submit" onClick={handleSubmit} style={styles.button}>Send Notification</button>
                </div>
            </div>
            {showWaitingModal && (
                <div className="modal show d-block" tabIndex={-1}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                            </div>
                            <div className="modal-body">
                                <>
                                    <div className="mb-3">
                                        <p>Processing Request. Do Not Refresh.</p>
                                    </div>
                                </>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showOverlay && (
                <div
                    className="modal-backdrop fade show"
                    onClick={() => {
                        setShowWaitingModal(false);
                        setShowOverlay(false);
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 1040, // Ensure this is below your modal's z-index
                    }}
                ></div>
            )}
        </div>

    );
};

export default AdminSendNotification;
