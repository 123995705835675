import React from 'react'

const AdminFooter = () => {
    return (
        <div className="container-fluid pt-4 px-4">
            <div className="bg-light rounded-top p-4">
                <div className="row">
                    <div className="col-12 col-sm-6 text-center text-sm-start">
                        &copy; 2024 Link Ur Codes. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminFooter