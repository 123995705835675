import React, { useEffect, useState } from 'react'
import '../../config/config'
import axios from 'axios'
import Navbar from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import AdmStaffNavBar from '../AdminStaff/AdmStaffNavBar'

const AddCollegeStaff = () => {

  const [inputField, setInputField] = useState({
    "collegeId": "",
    "collegeStaffName": "",
    "department": "",
    "clgStaffAddress": "",
    "email": "",
    "phNo": "",
    "aadharNo": "",
    "password": "",
    "confirmpassword": ""
  })

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [file, setFile] = useState(null)

  const [key, setKey] = useState('');

  const [fileType, setFileType] = useState("");

  const [showWaitingModal, setShowWaitingModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false); // New state for overlay

  const fileUploadHandler = (event) => {
    setErrors({});
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setErrors({});
      setFile(uploadedFile);
      const extension = uploadedFile.name.split('.').pop().toLowerCase();
      setFileType(extension);
    } else {
      setFile(null);
      setFileType("");
    }
  }



  const [errors, setErrors] = useState({})
  const [outputField, setOutputField] = useState([])
  const apiUrl = global.config.urls.api.server + "/api/lms/addclgstaff"
  const apiUrl2 = global.config.urls.api.server + "/api/lms/viewallcolleges"


  const getData = () => {
    // Retrieve key and token from sessionStorage without providing the key
    let currentKey, token;
    Object.entries(sessionStorage).forEach(([key, value]) => {
      if (key.includes('key')) {
        currentKey = value;
      } else if (key.includes('token')) {
        token = value;
      }
    });

    // Update the state with the current key
    setKey(currentKey);
    let axiosConfig = {
      headers: {
        'content-type': 'multipart/form-data',
        "Access-Control-Allow-Origin": "*",
        "token": token,
        "key": currentKey
      }
    };
    axios.post(apiUrl2, {}, axiosConfig).then(
      (response) => {
        if (response.data.data) {
          setOutputField(response.data.data)
        } else if (response.data.status === "Unauthorized User!!") {
          { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
          sessionStorage.clear()
        } else if (!response.data.data) {
          setOutputField([])
        } else {
          alert(response.data.status)
        }
      }
    )
  }

  const closeWaitingModal = () => {
    setShowOverlay(false)
    setShowWaitingModal(false)
  }

  const inputHandler = (event) => {
    setErrors({})
    setInputField({ ...inputField, [event.target.name]: event.target.value })
  };

  const readValue = (e) => {
    // Retrieve key and token from sessionStorage without providing the key
    let currentKey, token;
    Object.entries(sessionStorage).forEach(([key, value]) => {
      if (key.includes('key')) {
        currentKey = value;
      } else if (key.includes('token')) {
        token = value;
      }
    });

    // Update the state with the current key
    setKey(currentKey);
    e.preventDefault();
    // Check if file is uploaded, if not, use a default image
    let collegestaffImage = file ? file : getDefaultImage();
    const validationErrors = validateForm(inputField);
    if (Object.keys(validationErrors).length === 0) {
      let axiosConfig = {
        headers: {
          'content-type': 'multipart/form-data',
          "Access-Control-Allow-Origin": "*",
          "token": token,
          "key": currentKey
        }
      };
      let data = {
        "collegeId": inputField.collegeId,
        "collegeStaffName": inputField.collegeStaffName,
        "department": inputField.department,
        "clgStaffAddress": inputField.clgStaffAddress,
        "email": inputField.email,
        "phNo": inputField.phNo,
        "aadharNo": inputField.aadharNo,
        "password": inputField.password,
        "confirmpassword": inputField.confirmpassword,
        "profilePic": collegestaffImage
      }

      setShowWaitingModal(true)
      setShowOverlay(true)
      axios.post(apiUrl, data, axiosConfig).then(
        (response) => {
          if (response.data.status === "success") {
            closeWaitingModal()
            setTimeout(() => {
              alert("College Staff Added Successfully !!")
              window.location.reload()
              setInputField({
                collegeId: '',
                collegeStaffName: '',
                department: '',
                clgStaffAddress: '',
                email: '',
                phNo: '',
                aadharNo: '',
                password: '',
                confirmpassword: '',
                profilePic: ''
              })
            }, 500)
          } else if (response.data.status === "Validation failed" && response.data.data.dept) {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.data.dept)
            }, 500)
          } else if (response.data.status === "Validation failed" && response.data.data.name) {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.data.name)
            }, 500)
          } else if (response.data.status === "Validation failed" && response.data.data.address) {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.data.address)
            }, 500)
          } else if (response.data.status === "Validation failed" && response.data.data.email) {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.data.email)
            }, 500)
          } else if (response.data.status === "Validation failed" && response.data.data.mobile) {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.data.mobile)
            }, 500)
          } else if (response.data.status === "Validation failed" && response.data.data.aadharnumber) {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.data.aadharnumber)
            }, 500)
          } else if (response.data.status === "Validation failed" && response.data.data.password) {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.data.password)
            }, 500)
          } else if (response.data.status === "Unauthorized access!!") {
            { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
            sessionStorage.clear()
          } else {
            closeWaitingModal()
            setTimeout(() => {
              alert(response.data.status)
            }, 500)
          }
        }).catch(error => {
          closeWaitingModal()
          if (error.response) {
            // Extract the status code from the response
            const statusCode = error.response.status;

            if (statusCode === 400) {
              setTimeout(() => {
                alert(error.response.data.status)
              }, 500)
              // Additional logic for status 400
            } else if (statusCode === 500) {
              setTimeout(() => {
                alert(error.response.data.status)
              }, 500)
              // Additional logic for status 500
            } else {
              setTimeout(() => {
                alert(error.response.data.status)
              }, 500)
            }
          } else if (error.request) {
            setTimeout(() => {
              alert(error.request);
            }, 500)
          } else if (error.message) {
            setTimeout(() => {
              alert('Error', error.message);
            }, 500)
          } else {
            setTimeout(() => {
              alert(error.config);
            }, 500)
          }
        })
    } else {
      setErrors(validationErrors);
    }
  }

  // Function to get default image
  const getDefaultImage = () => {
    // You can replace this with your default image URL or base64 encoded image
    return '/person.svg';
  }



  const validateForm = (data) => {
    let errors = {};

    if (!data.collegeStaffName.trim()) {
      errors.collegeStaffName = 'Name is required';
    }

    if (!data.collegeId.trim()) {
      errors.collegeId = 'College Name is required';
    }
    if (!data.department.trim()) {
      errors.department = 'Department is required';
    }
    if (!data.clgStaffAddress.trim()) {
      errors.clgStaffAddress = 'Address is required';
    }
    if (!data.confirmpassword) {
      errors.confirmpassword = 'Confirm password is required';
    }
    if (file && fileType !== "jpg" && fileType !== "jpeg" && fileType !== "png" && fileType !== "webp" && fileType !== "heif") {
      errors.file = "File must be in jpg/jpeg/png/webp/heif format";
    }
    if (!data.phNo.trim()) {
      errors.phNo = 'Phone No is required';
    }

    if (!data.aadharNo.trim()) {
      errors.aadharNo = 'Aadhar No is required';
    }


    if (!data.email.trim()) {
      errors.email = 'Email is required';
    }

    if (!data.password.trim()) {
      errors.password = 'New Password is required';
    } else if (data.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    } else if (data.password.length > 12) {
      errors.password = 'Password should not exceed 12 characters';
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,12}$/.test(data.password)) {
      errors.password = 'Password should include one uppercase letter, one lowercase letter, numbers and special characters';
    }

    if (data.confirmpassword !== data.password) {
      errors.confirmpassword = 'Passwords do not match';
    }
    return errors;
  };

  useEffect(() => { getData() }, [])

  return (
    <div>
      {key === 'lmsappadmstaff' ? <AdmStaffNavBar /> : <Navbar />}
      <div className="container">
        <div class="bg-light py-3 py-md-5">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <div class="bg-white p-4 p-md-5 rounded shadow-sm">
                  <div class="row">
                    <div class="col-12">
                      <div class="text-center mb-5">
                        <Link to="#!">
                          <img src="https://www.linkurcodes.com/images/logo.png" alt="" width="175" height="57" />
                        </Link><br /><br />
                        <h3>Add College Staff</h3>
                      </div>
                    </div>
                  </div>
                  <div class="row gy-3 gy-md-4 overflow-hidden">
                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label for="" class="form-label">College Name <span class="text-danger">*</span></label>
                      <select name="collegeId" value={inputField.collegeId} id="collegeId" className="form-control" onChange={inputHandler}>
                        <option value="">Select</option>
                        {outputField.map((value) => {
                          return <option value={value.id}> {value.collegeName} </option>
                        })}
                      </select>
                      {errors.collegeId && <span style={{ color: 'red' }} className="error">{errors.collegeId}</span>}
                    </div>
                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label for="department" class="form-label">Department <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" name="department" id="department" onChange={inputHandler} value={inputField.department} />
                      {errors.department && <span style={{ color: 'red' }} className="error">{errors.department}</span>}
                    </div>
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <label for="collegeStaffName" class="form-label">College Staff Name <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" name="collegeStaffName" onChange={inputHandler} value={inputField.collegeStaffName} id="collegeStaffName" />
                      {errors.collegeStaffName && <span style={{ color: 'red' }} className="error">{errors.collegeStaffName}</span>}
                    </div>
                    <div class="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <label for="clgStaffAddress" class="form-label">Address <span class="text-danger">*</span></label>
                      <textarea onChange={inputHandler} name="clgStaffAddress" id="clgStaffAddress" cols="30" rows="5" className="input form-control" value={inputField.clgStaffAddress} ></textarea>
                      {errors.clgStaffAddress && <span style={{ color: 'red' }} className="error">{errors.clgStaffAddress}</span>}
                    </div>
                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label for="email" class="form-label">Email <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" name="email" id="email" onChange={inputHandler} value={inputField.email} />
                      {errors.email && <span style={{ color: 'red' }} className="error">{errors.email}</span>}
                    </div>
                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label for="PhNo" class="form-label">Mobile No <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" required="" name="phNo" id="phNo" onChange={inputHandler} value={inputField.phNo} />
                      {errors.phNo && <span style={{ color: 'red' }} className="error">{errors.phNo}</span>}
                    </div>
                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label htmlFor="profilePic" className="form-label">
                        Profile Image <span className="text-danger">*</span>
                      </label>
                      <input type="file" className="form-control" name="profilePic" id="profilePic" accept="image/*" onChange={fileUploadHandler} />
                      {errors.file && <span style={{ color: 'red' }} className="error">{errors.file}</span>}
                    </div>
                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label for="aadharNo" class="form-label">AadharNo <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" name="aadharNo" id="aadharNo" onChange={inputHandler} value={inputField.aadharNo} />
                      {errors.aadharNo && <span style={{ color: 'red' }} className="error">{errors.aadharNo}</span>}
                    </div>
                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label for="password" class="form-label">Password <span class="text-danger">*</span></label>
                      <div class="input-group">
                        <input type={showPassword ? "text" : "password"} class="form-control" name="password" id="password" onChange={inputHandler} value={inputField.password} />
                        <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                          <i className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"} id="togglePassword"></i>
                        </span>
                      </div>
                      {errors.password && <span style={{ color: 'red' }} className="error">{errors.password}</span>}
                    </div>
                    <div class="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <label for="password" class="form-label">Confirm Password <span class="text-danger">*</span></label>
                      <div class="input-group">
                        <input type={showConfirmPassword ? "text" : "password"} class="form-control" name="confirmpassword" id="confirmpassword" onChange={inputHandler} value={inputField.confirmpassword} />
                        <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                          <i className={showConfirmPassword ? "bi bi-eye" : "bi bi-eye-slash"} id="toggleConfirmPassword"></i>
                        </span>
                      </div>
                      {errors.confirmpassword && <span style={{ color: 'red' }} className="error">{errors.confirmpassword}</span>}
                    </div>
                    <div class="col-12">
                      <div class="d-grid">
                        <button class="btn btn-primary btn-lg" onClick={readValue} type="submit">Register</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <hr class="mt-5 mb-4 border-secondary-subtle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWaitingModal && (
        <div className="modal show d-block" tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
              </div>
              <div className="modal-body">
                <>
                  <div className="mb-3">
                    <p>Processing Request. Do Not Refresh.</p>
                  </div>
                </>
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>
        </div>
      )}
      {showOverlay && (
        <div
          className="modal-backdrop fade show"
          onClick={() => {
            setShowWaitingModal(false);
            setShowOverlay(false);
          }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 1040, // Ensure this is below your modal's z-index
          }}
        ></div>
      )}
    </div>


  )
}

export default AddCollegeStaff



