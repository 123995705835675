import React, { useEffect, useState } from 'react'
import '../../config/config'
import axios from 'axios'
import Navbar from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import AdmStaffNavBar from '../AdminStaff/AdmStaffNavBar'

const AdminViewAllCollege = () => {
    const [inputField, setInputField] = useState(
        {
            "collegeSearchQuery": ""
        }
    )
    const [collegeData, setCollegeData] = useState([])
    const [key, setKey] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [collegesPerPage] = useState(10); // Number of colleges per page
    const [deleteCollege, setDeleteCollege] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false); // New state for overlay

    const navigate = useNavigate()

    const apiUrl = global.config.urls.api.server + "/api/lms/viewallcolleges"
    const apiUrlTwo = global.config.urls.api.server + "/api/lms/deleteCollege"
    const apiUrl2 = global.config.urls.api.server + "/api/lms/changeregstatustoopen";
    const apiUrl3 = global.config.urls.api.server + "/api/lms/changeregstatustoclose";
    const apiUrl4 = global.config.urls.api.server + "/api/lms/searchCollege";

    const closeWaitingModal = () => {
        setShowOverlay(false)
        setShowWaitingModal(false)
    }

    const inputHandler = (event) => {
        setInputField({ ...inputField, [event.target.name]: event.target.value })
    }

    const taskScore = (id, collegeName) => {
        sessionStorage.setItem("ViewAllperformancecollegeId", id)
        sessionStorage.setItem("ViewAllperformancecollegeName", collegeName)
        navigate("/clgStaffviewAllClgPerformance")
    }

    const readSearchValue = () => {
        setIsLoading(true)
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig3 = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "token": token,
                "key": currentKey
            }
        }
        axios.post(apiUrl4, inputField, axiosConfig3).then(
            (response) => {
                if (response.data.status === "Search Item is required.") {
                    setIsLoading(false)
                    setTimeout(() => {
                        alert(response.data.status)
                        getData()
                    }, 500)
                } else if (response.data.data) {
                    setCollegeData(response.data.data)
                    setInputField(
                        {
                            "collegeSearchQuery": ""
                        }
                    )
                    setIsLoading(false)
                } else if (response.data.status === "Unauthorized User!!") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear()
                } else if (!response.data.data) {
                    setIsLoading(false)
                    setInputField(
                        {
                            "collegeSearchQuery": ""
                        }
                    )
                    setTimeout(() => {
                        getData()
                        alert("No Colleges Found !!")
                    }, 500)
                } else {
                    setIsLoading(false)
                    setInputField(
                        {
                            "collegeSearchQuery": ""
                        }
                    )
                    alert(response.data.status)
                }
            }
        )
    }

    const getData = () => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "token": token,
                "key": currentKey
            }
        }
        axios.post(apiUrl, {}, axiosConfig).then(
            (response) => {
                if (response.data.data) {
                    setIsLoading(false)
                    setCollegeData(response.data.data)
                } else {
                    if (response.data.status === "Unauthorized User!!") {
                        { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                        sessionStorage.clear()
                    } else {
                        if (!response.data.data) {
                            setIsLoading(false)
                            setCollegeData([])
                        } else {
                            setIsLoading(false)
                            alert(response.data.status)
                        }
                    }
                }
            }
        )
    }

    const handleClick = () => {
        let data = { "id": deleteCollege }
        let axiosConfigTwo = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "token": sessionStorage.getItem("admtoken"),
                "key": sessionStorage.getItem("admkey")
            }
        }
        setShowWaitingModal(true)
        setShowOverlay(true)
        axios.post(apiUrlTwo, data, axiosConfigTwo).then(
            (response) => {
                if (response.data.status === "College deleted.") {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert("College Deleted Successfully!!!")
                        getData();
                    }, 500)
                } else {
                    if (response.data.status === "Unauthorized User!!") {
                        navigate("/")
                        sessionStorage.clear()
                    } else {
                        closeWaitingModal()
                        setTimeout(() => {
                            alert(response.data.status)
                        }, 500)
                    }
                }
            }
        )
    }

    const UpdateClick = (id, collegeName) => {
        sessionStorage.setItem("clgId", id)
        sessionStorage.setItem("clgName", collegeName)
    }

    const readValue = (id) => {
        setDeleteCollege(id)
    };

    // Open Registration
    const openRegistration = (id) => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let collegeId = { "id": id };
        let axiosConfig2 = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "token": token,
                "key": currentKey
            }
        };
        setShowWaitingModal(true)
        setShowOverlay(true)
        axios.post(apiUrl2, collegeId, axiosConfig2).then(
            (response) => {
                if (response.data.status === "Registration Status Changed To Open.") {
                    closeWaitingModal()
                    setTimeout(() => {
                        getData();
                    }, 500)
                } else if (response.data.status === "Unauthorized User !!!") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear()
                } else {
                    closeWaitingModal()
                    setTimeout(() => {
                        // Handle other errors
                        alert(response.data.status);
                    }, 500)
                }
            }
        )
    };

    // Close Registration
    const closeRegistration = (id) => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let collegeId = { "id": id };
        let axiosConfig = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "token": token,
                "key": currentKey
            }
        };
        setShowWaitingModal(true)
        setShowOverlay(true)
        axios.post(apiUrl3, collegeId, axiosConfig).then(
            (response) => {
                if (response.data.status === "Registration Status Changed To Unavailable.") {
                    closeWaitingModal()
                    setTimeout(() => {
                        getData();
                    }, 500)
                } else {
                    if (response.data.status === "Unauthorized User !!!") {
                        { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                        sessionStorage.clear()
                    } else {
                        closeWaitingModal()
                        setTimeout(() => {
                            alert(response.data.status);
                        }, 500)
                    }
                }
            }
        )
    };

    // Logic for displaying current colleges
    const indexOfLastCollege = currentPage * collegesPerPage;
    const indexOfFirstCollege = indexOfLastCollege - collegesPerPage;
    const currentColleges = collegeData ? collegeData.slice(indexOfFirstCollege, indexOfLastCollege) : [];

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // Total pages
    let totalPages = []
    if (collegeData && collegeData.length > 0) {
        totalPages = Math.ceil(collegeData.length / collegesPerPage);
    }

    const calculateSerialNumber = (index) => {
        return ((currentPage - 1) * collegesPerPage) + index + 1;
    }

    // Integration of new pagination logic
    const startPage = currentPage > 2 ? currentPage - 2 : 1;
    const endPage = startPage + 4 <= totalPages ? startPage + 4 : totalPages;

    useEffect(() => { getData() }, [])

    return (
        <div>
            {key === 'lmsappadmstaff' ? <AdmStaffNavBar /> : <Navbar />}
            <br />
            <div className="flex justify-between items-center mx-4 my-4">
                <div></div>
                <strong>View All Colleges</strong>
                <div></div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <div className="row g-3">
                        <div className="col col-md-6 mx-auto"> {/* Center-align the search bar */}
                            <div className="input-group mb-3"> {/* Use an input group */}
                                <input onChange={inputHandler} type="text" className="form-control" name="collegeSearchQuery" value={inputField.collegeSearchQuery} placeholder='College Name/Address/Website/Email/Phone No./Mobile No.' />
                                <button onClick={readSearchValue} className="btn btn-warning ms-2">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {isLoading ? <div className="flex justify-center items-center h-full">
                <div className="text-center py-20">
                    <div>Loading...</div>
                </div>
            </div> : (<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                S/N
                            </th>
                            <th scope="col" className="px-6 py-3">
                                College Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                College Code
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Address
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Website
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Phone No:
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Mobile No:
                            </th>
                            <th scope="col" className="px-6 py-3">

                            </th>
                            <th scope="col" className="px-6 py-3">

                            </th>
                            <th scope="col" className="px-6 py-3">

                            </th>
                            <th scope="col" className="px-6 py-3">

                            </th>
                            {key === 'lmsapp' && (
                                <th scope="col" className="px-6 py-3">

                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {currentColleges.map((value, index) => {
                            return <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">
                                    {calculateSerialNumber(index)}
                                </td>
                                <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                    <img className="w-10 h-10 rounded-full" src={value.collegeImage} alt="" />
                                    <div className="ps-3">
                                        <div className="text-base font-semibold">{value.collegeName}</div>
                                        <div className="font-normal text-gray-500">{value.email}</div>
                                    </div>
                                </th>
                                <td className="px-6 py-4">
                                    {value.collegeCode}
                                </td>
                                <td className="px-6 py-4">
                                    {value.collegeAddress}
                                </td>
                                <td className="px-6 py-4">
                                    {value.website}
                                </td>
                                <td className="px-6 py-4">
                                    {value.collegePhNo ? value.collegePhNo : <p>NIL</p>}
                                </td>
                                <td className="px-6 py-4">
                                    {value.collegeMobileNumber}
                                </td>
                                <td className="px-6 py-4">
                                    {value.registrationStatus === 0 && (
                                        <button onClick={() => openRegistration(value.id)} style={{ fontSize: '12px' }} className="btn btn-primary">Open Registration</button>
                                    )}
                                    {value.registrationStatus === 1 && (
                                        <button onClick={() => { closeRegistration(value.id) }} style={{ fontSize: '12px' }} className="btn btn-danger">Close Registration</button>
                                    )}
                                </td>
                                <td className="px-6 py-4">
                                    <Link to="/adminviewallbatches" onClick={() => { UpdateClick(value.id, value.collegeName) }} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">View Batches</Link>
                                </td>
                                <td className="px-6 py-4">
                                    <Link to="/adminUpdateclg" onClick={() => { UpdateClick(value.id) }} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Update College</Link>
                                </td>
                                {key === 'lmsapp' && (
                                    <td className="px-6 py-4">
                                        <Link onClick={() => { readValue(value.id) }} className="font-medium text-blue-600 dark:text-blue-500 hover:underline" data-bs-toggle="modal" data-bs-target="#exampleModal">Delete College</Link>
                                    </td>
                                )}
                                <td className="px-6 py-4">
                                    <button onClick={() => taskScore(value.id, value.collegeName)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline" style={{ marginRight: '20px' }}>
                                        View Performance
                                    </button>
                                </td>
                            </tr>
                        })}

                        {currentColleges.length === 0 && (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td colSpan="8" className="px-6 py-4" style={{ textAlign: 'center' }}>
                                    No Colleges Found !!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>)}

            <div className="row">
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Are you sure you want to delete this college?</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>This action cannot be undone.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No, cancel</button>
                                <button onClick={() => { handleClick() }} type="button" className="btn btn-danger" data-bs-dismiss="modal">Yes, I'm sure</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!isLoading && currentColleges.length > 0 && (
                <div className="flex items-center justify-between bg-white px-6 py-4 sm:px-6">
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">{indexOfFirstCollege + 1}</span> to <span className="font-medium">{indexOfLastCollege > collegeData.length ? collegeData.length : indexOfLastCollege}</span> of <span className="font-medium">{collegeData.length}</span> results
                            </p>
                        </div>
                        <div>
                            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                <button onClick={() => currentPage > 1 && paginate(currentPage - 1)} className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage === 1 ? 'cursor-not-allowed text-gray-500' : 'text-gray-700 hover:bg-gray-50'} disabled:opacity-50`} disabled={currentPage === 1}>
                                    <span className="sr-only">Previous</span>
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                {/* Dynamically generate Link components for each page number */}
                                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                                    <button key={startPage + index} onClick={() => paginate(startPage + index)} className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${currentPage === startPage + index ? 'bg-indigo-600 text-white' : 'text-gray-700 hover:bg-gray-50'}`}>
                                        {startPage + index}
                                    </button>
                                ))}
                                <button onClick={() => currentPage < totalPages && paginate(currentPage + 1)} className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage === totalPages ? 'cursor-not-allowed text-gray-500' : 'text-gray-700 hover:bg-gray-50'} disabled:opacity-50`} disabled={currentPage === totalPages}>
                                    <span className="sr-only">Next</span>
                                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>
            )}
            {showWaitingModal && (
                <div className="modal show d-block" tabIndex={-1}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                            </div>
                            <div className="modal-body">
                                <>
                                    <div className="mb-3">
                                        <p>Processing Request. Do Not Refresh.</p>
                                    </div>
                                </>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showOverlay && (
                <div
                    className="modal-backdrop fade show"
                    onClick={() => {
                        setShowWaitingModal(false);
                        setShowOverlay(false);
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 1040, // Ensure this is below your modal's z-index
                    }}
                ></div>
            )}
        </div >

    )
}

export default AdminViewAllCollege
