import React, { useEffect, useState } from 'react'
import Navbar from './Navbar';
import axios from 'axios';
import '../../config/config'
import { Link, useNavigate } from 'react-router-dom';
import AdmStaffNavBar from '../AdminStaff/AdmStaffNavBar';

const AdminAddSession = () => {

    const [inputField, setInputField] = useState({
        "collegeId": "",
        "batchId": "",
        "sessionName": "",
        "date": "",
        "time": "",
        "type": "",
        "remarks": "",
        "venueORlink": "",
        "trainerId": ""
    })

    const navigate = useNavigate()

    const [outputField, setOutputField] = useState([])

    const [errors, setErrors] = useState({})

    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false); // New state for overlay

    const [batches, setBatches] = useState([])

    const [trainers, setTrainers] = useState([])

    const [key, setKey] = useState('');

    const apiUrl = global.config.urls.api.server + "/api/lms/createsession";
    const apiUrl2 = global.config.urls.api.server + "/api/lms/viewallcolleges";
    const batchUrl = global.config.urls.api.server + "/api/lms/adminviewbatch";
    const trainerUrl = global.config.urls.api.server + "/api/lms/viewAllTrainer";

    const getTrainer = () => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig = {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                "token": token,
                "key": currentKey
            }
        };
        axios.post(trainerUrl, {}, axiosConfig).then(
            (response) => {
                if (response.data.Trainers) {
                    setTrainers(response.data.Trainers)
                } else if (response.data.status === "Unauthorized access!!") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear()
                } else if (!response.data.Trainers) {
                    setTrainers([])
                } else {
                    alert(response.data.status)
                }
            }
        )
    }

    const closeWaitingModal = () => {
        setShowOverlay(false)
        setShowWaitingModal(false)
    }

    const getData = () => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig = {
            headers: {
                'content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                "token": token,
                "key": currentKey
            }
        };
        axios.post(apiUrl2, {}, axiosConfig).then(
            (response) => {
                if (response.data.data) {
                    setOutputField(response.data.data)
                } else if (response.data.status === "Unauthorized User!!") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear()
                } else if (!response.data.data) {
                    setOutputField([])
                } else {
                    alert(response.data.status)
                }
            }
        )
    }

    const getBatches = (collegeId) => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let axiosConfig2 = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                "token": token,
                "key": currentKey
            }
        };
        axios.post(batchUrl, { collegeId }, axiosConfig2).then((response) => {
            if (response.data) {
                setBatches(response.data)
            } else if (response.data.status === "Unauthorized User!!") {
                { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                sessionStorage.clear()
            } else if (!response.data) {
                setBatches([])
            } else {
                alert(response.data.status)
            }
        })
    }

    const handleCollegeChange = (event) => {
        const selectedCollegeId = event.target.value;
        setInputField(prevState => ({ ...prevState, collegeId: selectedCollegeId }))
        getBatches(selectedCollegeId);
    }


    const inputHandler = (event) => {
        setErrors({})
        setInputField({ ...inputField, [event.target.name]: event.target.value });
    };

    const readValue = (e) => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        let addedBy;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        if (currentKey === 'lmsapp') {
            addedBy = 0
        } else {
            addedBy = sessionStorage.getItem("admstaffId")
        }
        e.preventDefault();
        const validationErrors = validateForm(inputField);
        if (Object.keys(validationErrors).length === 0) {
            let axiosConfig3 = {
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                    "token": token,
                    "key": currentKey
                }
            }
            let data = {
                "batchId": inputField.batchId,
                "sessionName": inputField.sessionName,
                "date": inputField.date,
                "time": inputField.time,
                "type": inputField.type,
                "remarks": inputField.remarks ? inputField.remarks : null,
                "venueORlink": inputField.venueORlink,
                "trainerId": inputField.trainerId,
                "addedby": addedBy
            }
            setShowWaitingModal(true)
            setShowOverlay(true)
            axios.post(apiUrl, data, axiosConfig3).then((response) => {
                if (response.data.status === 'success') {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert('Session Added Successfully !!');
                        setInputField({
                            collegeId: '',
                            batchId: '',
                            sessionName: '',
                            date: '',
                            time: '',
                            type: '',
                            remarks: '',
                            venueORlink: '',
                            trainerId: ''
                        })
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.batchId) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.batchId)
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.sessionName) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.sessionName)
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.date) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.date)
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.time) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.time)
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.type) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.type)
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.remarks) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.remarks)
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.venueORlink) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.venueORlink)
                    }, 500)
                } else if (response.data.status === "Validation failed" && response.data.data.trainerId) {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.data.trainerId)
                    }, 500)
                } else if (response.data.status === "Unauthorized User!!") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear()
                } else {
                    closeWaitingModal()
                    setTimeout(() => {
                        alert(response.data.status)
                    }, 500)
                }
            })
        } else {
            setErrors(validationErrors);
        }
    }

    const validateForm = (data) => {
        let errors = {};

        if (!data.batchId.trim()) {
            errors.batchId = 'Batch Name is required';
        }
        if (!data.collegeId.trim()) {
            errors.collegeId = 'College Name is required';
        }
        if (!data.sessionName.trim()) {
            errors.sessionName = 'Session name is required';
        }
        if (!data.date.trim()) {
            errors.date = 'Date is required';
        }
        if (!data.venueORlink.trim()) {
            errors.venueORlink = 'Venue or Link is required';
        }
        if (!data.trainerId.trim()) {
            errors.trainerId = 'Trainer Name is required';
        }
        if (!data.time.trim()) {
            errors.time = 'Time is required';
        }
        if (!data.type.trim()) {
            errors.type = 'Type is required';
        } else if (data.type === "Online") {
            // If type is Online, ensure there's a link and it's either Google Meet or Zoom
            if (!data.venueORlink.trim()) {
                errors.venueORlink = 'Meeting Link is required';
            } else {
                const isGoogleMeetLink = data.venueORlink.includes('meet.google.com/');
                const isZoomLink = data.venueORlink.includes('zoom.us/');
                const isTeamsLink = data.venueORlink.includes('teams.microsoft.com/');

                // Check if the link is not a Google Meet or Zoom link
                if (!isGoogleMeetLink && !isZoomLink && !isTeamsLink) {
                    errors.venueORlink = 'Please provide a valid google meet, zoom or teams link';
                }
            }
        } else if (data.type === "Recorded") {
            if (!data.venueORlink.trim()) {
                errors.venueORlink = 'Recorded Video Link is required';
            } else {
                const isYouTubeLink = data.venueORlink.includes('youtube.com/');
                const isVimeoLink = data.venueORlink.includes('vimeo.com/');

                if (!isYouTubeLink && !isVimeoLink) {
                    errors.venueORlink = 'Please provide a valid link';
                }
            }
        } else if (data.type === "Offline" && !data.venueORlink.trim()) {
            errors.venueORlink = 'Please provide a valid venue';
        }

        return errors;
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getTrainer()
    }, [])

    return (
        <div>
            {key === 'lmsappadmstaff' ? <AdmStaffNavBar /> : <Navbar />}
            <div className="bg-light py-3 py-md-5">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-9 col-xxl-8">
                            <div className="bg-white p-4 p-md-5 rounded shadow-sm">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center mb-5">
                                            <Link to="#!">
                                                <img
                                                    src="https://www.linkurcodes.com/images/logo.png"
                                                    alt=""
                                                    width="175"
                                                    height="57"
                                                />
                                            </Link>
                                            <br />
                                            <br />
                                            <h3>Add Session</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gy-3 gy-md-4 overflow-hidden">
                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="collegeId" className="form-label">
                                            College Name <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="collegeId"
                                            value={inputField.collegeId}
                                            onChange={handleCollegeChange}
                                            id="collegeId"
                                            className="form-control">
                                            <option value="">Select</option>
                                            {outputField.map((value) => {
                                                return <option value={value.id}> {value.collegeName} </option>
                                            })}
                                        </select>
                                        {errors.collegeId && (
                                            <span style={{ color: 'red' }} className="error">
                                                {errors.collegeId}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="batchName" className="form-label">
                                            Batch Name <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="batchId"
                                            id="batchId"
                                            className="form-control"
                                            value={inputField.batchId}
                                            onChange={inputHandler}>
                                            <option value="">Select</option>
                                            {batches.data && batches.data.map((value) => {
                                                return <option value={value.id}> {value.batchName} </option>;
                                            })}
                                        </select>
                                        {errors.batchId && (<span style={{ color: 'red' }} className="error">{errors.batchId}</span>)}
                                    </div>
                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="sessionName" className="form-label">
                                            Session Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="sessionName"
                                            id="sessionName"
                                            value={inputField.sessionName}
                                            onChange={inputHandler}
                                        />
                                        {errors.sessionName && (<span style={{ color: 'red' }} className="error">{errors.sessionName}</span>)}
                                    </div>
                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="date" className="form-label">
                                            Date <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="date"
                                            id="date"
                                            value={inputField.date}
                                            onChange={inputHandler}
                                        />
                                        {errors.date && (<span style={{ color: 'red' }} className="error">{errors.date}</span>)}
                                    </div>
                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="time" className="form-label">
                                            Time<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="time"
                                            id="time"
                                            value={inputField.time}
                                            onChange={inputHandler}
                                        />
                                        {errors.time && (<span style={{ color: 'red' }} className="error">{errors.time}</span>)}
                                    </div>
                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="type" className="form-label">
                                            Type <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            name="type"
                                            id="type"
                                            value={inputField.type}
                                            onChange={inputHandler}
                                        >
                                            <option value="">Select Type</option>
                                            <option value="Online">Online</option>
                                            <option value="Offline">Offline</option>
                                            <option value="Recorded">Recorded</option>
                                        </select>
                                        {errors.type && (<span style={{ color: 'red' }} className="error">{errors.type}</span>)}
                                    </div>

                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="venueORlink" className="form-label">
                                            Venue or Link <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="venueORlink"
                                            id="venueORlink"
                                            value={inputField.venueORlink}
                                            onChange={inputHandler}
                                        />
                                        {errors.venueORlink && (<span style={{ color: 'red' }} className="error">{errors.venueORlink}</span>)}
                                    </div>
                                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label htmlFor="trainerId" className="form-label">
                                            Trainer <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            name="trainerId"
                                            value={inputField.trainerId}
                                            onChange={inputHandler}
                                            id="trainerId"
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {trainers.map((trainer) => {
                                                return <option key={trainer.id} value={trainer.id}>
                                                    {trainer.trainerName}
                                                </option>
                                            })}
                                        </select>
                                        {errors.trainerId && (<span style={{ color: 'red' }} className="error">{errors.trainerId}</span>)}
                                    </div>
                                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <label htmlFor="remarks" className="form-label">
                                            Remarks (Optional)
                                        </label>
                                        <textarea
                                            className="form-control"
                                            name="remarks"
                                            id="remarks"
                                            rows="3"
                                            value={inputField.remarks}
                                            onChange={inputHandler}
                                        ></textarea>
                                        {errors.remarks && (<span style={{ color: 'red' }} className="error">{errors.remarks}</span>)}
                                    </div>

                                    <div className="col-12">
                                        <div className="d-grid">
                                            <button
                                                className="btn btn-primary btn-lg"
                                                type="submit"
                                                onClick={readValue}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <hr className="mt-5 mb-4 border-secondary-subtle" />
                                        <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center">
                                            &copy; 2024 Link Ur Codes. All rights reserved.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showWaitingModal && (
                <div className="modal show d-block" tabIndex={-1}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                            </div>
                            <div className="modal-body">
                                <>
                                    <div className="mb-3">
                                        <p>Processing Request. Do Not Refresh.</p>
                                    </div>
                                </>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showOverlay && (
                <div
                    className="modal-backdrop fade show"
                    onClick={() => {
                        setShowWaitingModal(false);
                        setShowOverlay(false);
                    }}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 1040, // Ensure this is below your modal's z-index
                    }}
                ></div>
            )}
        </div>
    );
}

export default AdminAddSession