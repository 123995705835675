import React, { useEffect, useState } from 'react';
import '../../config/config';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import AdmStaffNavBar from '../AdminStaff/AdmStaffNavBar';

const AdminUpdateTask = () => {
    const [taskData, setTaskData] = useState([]);
    const [key, setKey] = useState('');
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState("")
    const [fileType, setFileType] = useState("");
    const [updateField, setUpdateField] = useState({
        "id": sessionStorage.getItem('taskId'),
        "batchId": '',
        "taskTitle": '',
        "taskDesc": '',
        "taskType": '',
        "totalScore": '',
        "dueDate": '',
        "taskFileUpload": null,
    });
    const apiURL = global.config.urls.api.server + '/api/lms/viewOneTask';
    const apiUrl2 = global.config.urls.api.server + '/api/lms/updateTask';
    const navigate = useNavigate();

    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false); // New state for overlay

    const updateHandler = (event) => {
        setErrors({});
        setUpdateField({ ...updateField, [event.target.name]: event.target.value });
    }

    const closeWaitingModal = () => {
        setShowOverlay(false)
        setShowWaitingModal(false)
    }

    const fileUploadHandler = (event) => {
        setErrors({});
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            setErrors({});
            setFile(uploadedFile);
            const extension = uploadedFile.name.split('.').pop().toLowerCase();
            setFileType(extension);
        } else {
            setFile(null);
            setFileType("");
        }
    }

    const readNewValue = (e) => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let updatedby;
        if (currentKey === 'lmsapp') {
            updatedby = 0
        } else {
            updatedby = sessionStorage.getItem("admstaffId")
        }
        e.preventDefault();
        const validationErrors = validateForm(updateField);
        if (Object.keys(validationErrors).length === 0) {
            let axiosConfig2 = {
                headers: {
                    'content-type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*",
                    "token": token,
                    "key": currentKey
                }
            }
            let data = {}
            if (file) {
                data = {
                    "id": sessionStorage.getItem('taskId'),
                    "batchId": updateField.batchId,
                    "taskTitle": updateField.taskTitle,
                    "taskDesc": updateField.taskDesc,
                    "taskType": updateField.taskType,
                    "totalScore": updateField.totalScore,
                    "dueDate": updateField.dueDate,
                    "taskFileUpload": file,
                    "updatedby": updatedby
                }
            } else {
                data = {
                    "id": sessionStorage.getItem('taskId'),
                    "batchId": updateField.batchId,
                    "taskTitle": updateField.taskTitle,
                    "taskDesc": updateField.taskDesc,
                    "taskType": updateField.taskType,
                    "totalScore": updateField.totalScore,
                    "dueDate": updateField.dueDate,
                    "updatedby": updatedby
                }
            }
            setShowWaitingModal(true)
            setShowOverlay(true)
            axios.post(apiUrl2, data, axiosConfig2).then(
                (Response) => {
                    if (Response.data.status === "success") {
                        closeWaitingModal()
                        setTimeout(() => {
                            setUpdateField({
                                "id": sessionStorage.getItem('taskId'),
                                "batchId": '',
                                "taskTitle": '',
                                "taskDesc": '',
                                "taskType": '',
                                "totalScore": '',
                                "dueDate": '',
                                "taskFileUpload": null,
                            })
                            alert("Task Updated Successfully")
                            navigate(-1)
                        }, 500)
                    } else {
                        closeWaitingModal()
                        if (Response.data.status === "Validation failed" && Response.data.data.value) {
                            setTimeout(() => { alert(Response.data.data.value) }, 500)
                        } else {
                            if (Response.data.status === "Validation failed" && Response.data.data.name) {
                                setTimeout(() => { alert(Response.data.data.name) }, 500)
                            } else {
                                if (Response.data.status === "Validation failed" && Response.data.data.desc) {
                                    setTimeout(() => { alert(Response.data.data.desc) }, 500)
                                } else {
                                    if (Response.data.status === "Validation failed" && Response.data.data.type) {
                                        setTimeout(() => { alert(Response.data.data.type) }, 500)
                                    } else {
                                        if (Response.data.status === "Validation failed" && Response.data.data.score) {
                                            setTimeout(() => { alert(Response.data.data.score) }, 500)
                                        } else {
                                            if (Response.data.status === "Validation failed" && Response.data.data.dueDate) {
                                                setTimeout(() => { alert(Response.data.data.dueDate) }, 500)
                                            } else {
                                                if (Response.data.status === "Unauthorized access!!") {
                                                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                                                    sessionStorage.clear()
                                                } else {
                                                    closeWaitingModal()
                                                    setTimeout(() => {
                                                        alert(Response.data.status)
                                                    }, 500)
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }

                }
            ).catch(error => {
                closeWaitingModal()
                if (error.response) {
                    // Extract the status code from the response
                    const statusCode = error.response.status;

                    if (statusCode === 400) {
                        setTimeout(() => {
                            alert(error.response.data.status)
                        }, 500)
                    } else if (statusCode === 500) {
                        setTimeout(() => {
                            alert(error.response.data.status)
                        }, 500)
                    } else {
                        setTimeout(() => {
                            alert(error.response.data.status)
                        }, 500)
                    }
                } else if (error.request) {
                    setTimeout(() => {
                        alert(error.request);
                    }, 500)
                } else if (error.message) {
                    setTimeout(() => {
                        alert('Error', error.message);
                    }, 500)
                } else {
                    setTimeout(() => {
                        alert(error.config);
                    }, 500)
                }
            })
        } else {
            setErrors(validationErrors);
        }
    }

    const validateForm = (data) => {
        let errors = {};

        if (!data.batchId) {
            errors.batchId = 'Batch Name is required';
        }
        if (!data.taskTitle) {
            errors.taskTitle = 'Task Title is required';
        }
        if (!data.taskDesc) {
            errors.taskDesc = 'Task Description is required';
        }
        if (!data.taskType) {
            errors.taskType = 'Task Type is required';
        }
        if (!data.totalScore) {
            errors.totalScore = 'Total Score is required';
        } else if (!/^[1-9]\d*$/.test(data.totalScore)) {
            errors.totalScore = 'Total score should be a number greater than zero';
        }
        if (!data.dueDate) {
            errors.dueDate = 'Due Date is required';
        }
        if (file && fileType !== "docx" && fileType !== "pdf") {
            errors.file = "File must be in PDF or DOCX format";
        }

        return errors;
    }

    const getData = () => {
        // Retrieve key and token from sessionStorage without providing the key
        let currentKey, token;
        Object.entries(sessionStorage).forEach(([key, value]) => {
            if (key.includes('key')) {
                currentKey = value;
            } else if (key.includes('token')) {
                token = value;
            }
        });

        // Update the state with the current key
        setKey(currentKey);
        let data = { "id": sessionStorage.getItem('taskId') };
        let axiosConfig = {
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                "token": token,
                "key": currentKey,
            }
        }
        axios.post(apiURL, data, axiosConfig).then((response) => {
            if (response.data.data) {
                setTaskData(response.data.data);
                setUpdateField(response.data.data);
            } else {
                if (response.data.status === "Unauthorized Access!!!") {
                    { key === 'lmsapp' ? navigate("/") : navigate("/admstafflogin") }
                    sessionStorage.clear()
                } else {
                    if (!response.data.data) {
                        setTaskData([])
                        setUpdateField({
                            "id": '',
                            "batchId": '',
                            "taskTitle": '',
                            "taskDesc": '',
                            "taskType": '',
                            "totalScore": '',
                            "dueDate": '',
                            "taskFileUpload": '',
                        })
                    } else {
                        alert(response.data.status)
                    }
                }
            }
        });
    };

    const handleBackButton = () => {
        navigate(-1)
    }

    useEffect(() => {
        const formattedDate = formatDate(updateField.dueDate);
        setUpdateField({ ...updateField, dueDate: formattedDate });
    }, [updateField.dueDate]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            {key === 'lmsappadmstaff' ? <AdmStaffNavBar /> : <Navbar />}
            <div className="container">

                <div className="row">
                    <div className="col-lg-12 mb-4 mb-sm-5">
                        <br></br>
                        <div className="flex justify-between items-center mx-4 my-4">
                            <button onClick={() => navigate(-1)} className="btn bg-gray-500 text-white px-4 py-2 rounded-md">Back</button>

                            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Update Task Details</p>

                            <div></div>
                        </div>
                        <div className="card card-style1 --bs-primary-border-subtle border-5">
                            <div className="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 px-xl-10">
                                        <ul className="list-unstyled mb-1-9">
                                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <input
                                                    type="hidden"
                                                    className="form-control"
                                                    name="id"
                                                    value={updateField.id}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <input
                                                    onChange={updateHandler}
                                                    type="hidden"
                                                    className="form-control"
                                                    name="batchId"
                                                    value={updateField.batchId}
                                                />
                                                {errors.batchId && (<span style={{ color: 'red' }} className="error">{errors.batchId}</span>)}
                                            </div>
                                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <label htmlFor="" className="form-label">
                                                    Task Title
                                                </label>
                                                <input
                                                    onChange={updateHandler}
                                                    type="text"
                                                    className="form-control"
                                                    name="taskTitle"
                                                    value={updateField.taskTitle}
                                                />
                                                {errors.taskTitle && (<span style={{ color: 'red' }} className="error">{errors.taskTitle}</span>)}
                                            </div>
                                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <label htmlFor="" className="form-label">
                                                    Task Description
                                                </label>
                                                <input
                                                    onChange={updateHandler}
                                                    type="text"
                                                    className="form-control"
                                                    name="taskDesc"
                                                    value={updateField.taskDesc}
                                                />
                                                {errors.taskDesc && (<span style={{ color: 'red' }} className="error">{errors.taskDesc}</span>)}
                                            </div>
                                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <label htmlFor="" className="form-label">
                                                    Task Type
                                                </label>
                                                <select
                                                    className="form-select"
                                                    name="taskType"
                                                    id="taskType"
                                                    value={updateField.taskType}
                                                    onChange={updateHandler}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="Mini Project">Mini Project</option>
                                                    <option value="Project">Project</option>
                                                    <option value="Live Project">Live Project</option>
                                                    <option value="Daily Task">Daily Task</option>
                                                    <option value="Weekly Task">Weekly Task</option>
                                                    <option value="Homework">Homework</option>
                                                </select>
                                                {errors.taskType && (<span style={{ color: 'red' }} className="error">{errors.taskType}</span>)}
                                            </div>
                                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <label htmlFor="" className="form-label">
                                                    Total Score
                                                </label>
                                                <input
                                                    onChange={updateHandler}
                                                    type="text"
                                                    className="form-control"
                                                    name="totalScore"
                                                    value={updateField.totalScore}
                                                />
                                                {errors.totalScore && (<span style={{ color: 'red' }} className="error">{errors.totalScore}</span>)}
                                            </div>
                                            <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                <label htmlFor="" className="form-label">
                                                    Due Date
                                                </label>
                                                <input
                                                    onChange={updateHandler}
                                                    type="date"
                                                    className="form-control"
                                                    name="dueDate"
                                                    value={updateField.dueDate}
                                                />
                                                {errors.dueDate && (<span style={{ color: 'red' }} className="error">{errors.dueDate}</span>)}
                                            </div>
                                            <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                                <label for="taskFileUpload" className="form-label">
                                                    File <span className="text-danger">*</span>
                                                </label>
                                                <input onChange={fileUploadHandler} type="file" className="form-control" name="taskFileUpload" id="taskFileUpload" accept="application/pdf" />
                                                {errors.file && (<span style={{ color: 'red' }} className="error">{errors.file}</span>)}
                                            </div>
                                            <br></br>
                                            <div className="col col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                                <button onClick={readNewValue} className="btn btn-warning">
                                                    Update
                                                </button>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showWaitingModal && (
                    <div className="modal show d-block" tabIndex={-1}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel"></h1>
                                </div>
                                <div className="modal-body">
                                    <>
                                        <div className="mb-3">
                                            <p>Processing Request. Do Not Refresh.</p>
                                        </div>
                                    </>
                                </div>
                                <div className="modal-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showOverlay && (
                    <div
                        className="modal-backdrop fade show"
                        onClick={() => {
                            setShowWaitingModal(false);
                            setShowOverlay(false);
                        }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            zIndex: 1040, // Ensure this is below your modal's z-index
                        }}
                    ></div>
                )}
            </div></div>
    )
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    } else {
        return '';
    }
};

export default AdminUpdateTask